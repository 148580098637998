import React, { useState } from "react";
import MySelectionListControls from "./MySelectionListControls";
import ResultContainer from "../search/ResultContainer";

/** CSS */
import styles from "./MySelections.module.scss";

function MySelections() {
  const [fileName, setFileName] = useState('image');

  return (
    <>
      <div>
        <MySelectionListControls fileName={fileName} setFileName={setFileName} />
        <ResultContainer mode="selection" fileName={fileName}></ResultContainer>
      </div>
    </>
  );
}

export default MySelections;
