import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom';

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

/** Scss */
import styles from "./Register.module.scss";

import Select from 'react-dropdown-select';
import { ReactComponent as LeftArrow } from '../../assets/images/leftArrow.svg';
import { useAPI } from '../../util/useAPI';
import { MessageContext } from '../../util/messageContext';
import Checkbox from '../buttons/Checkbox';

function Register() {
    const history = useHistory();
    const [surname, setSurName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [type, setType] = useState("Select Account Type");
    const [concept, setConcept] = useState("");
    const [comments, setComments] = useState("");
    const [termsAccepted, setTermsAccepted] = useState("");
    const { setMessage } = useContext(MessageContext);
    const [accountTypes, setAccountTypes] = useState([]);
    const [concepts, setConcepts] = useState([]);

    const [{ data, loading, error }, registerAPIHandler] = useAPI(
        {
            url: "/auth/register",
            method: "POST",
        },
        { manual: true }
    );

    const [{ data: accountTypesResponse }] = useAPI(
        {
            url: "/accounts/types",
            method: "GET",
        },
        { manual: false }
    );

    const [{ data: conceptsResponse }] = useAPI(
        {
            url: "/accounts/concepts",
            method: "GET",
        },
        { manual: false }
    );

    const clickHandler = () => {
        let requestData = {
            data: {
                surname: surname,
                email: email,
                company: company,
                type: type,
                concept: concept,
                comments: comments,
                toc: termsAccepted
            }
        };

        if (validateForm(requestData)) {
            registerAPIHandler(requestData);
        }
    }

    const selectType = (e) => {
        if (e[0]) {
            setType(e[0].name);
        }
    }

    const selectConcept = (e) => {
        if (e[0]) {
            setConcept(e[0].name);
        }
    }

    function validateForm(requestData) {
        let errors = [];
        var regex = /\S+@\S+\.\S+/;
        let data = requestData.data;
        for (var key in data) {
            if (data.hasOwnProperty(key) && data[key].length === 0 && key !== 'toc') {
                errors.push({ field: key, error: key + " is required" });
            }

            if (key === 'email' && !regex.test(data[key])) {
                errors.push({ field: key, error: key + " is not a valid" });
            }
        }


        if (errors.length > 0) {
            setMessage({ type: 'toast', title: 'Validation Error', message: errors.map(e => (<li>{e.error}</li>)) });
            return false;
        }

        return true;
    }

    useEffect(() => {
        if (data && data.data) {
            history.push('/registrationComplete');
        }
        if (error) {
            setMessage({ type: 'toast', title: 'Unable to Login', message: error })
        }
        if (accountTypesResponse && accountTypesResponse.data) {
            let types = [];
            let data = accountTypesResponse.data.types;
            for (var code in data) {
                types.push({ code: code, value: data[code] });
            }
            setAccountTypes(types);
        }
        if (conceptsResponse && conceptsResponse.data) {
            let concepts = []
            let options = conceptsResponse.data.concepts;
            for (var code in options) {
                concepts.push({ code: code, value: options[code] });
            }
            setConcepts(concepts);
        }
    }, [data, error, accountTypesResponse, conceptsResponse]);

    const options = accountTypes.map(type => {
        return { name: type.value }
    });

    const conceptOptions = concepts.map(concept => {
        return { name: concept.value }
    });

    return (
        <Container>
            <Row className={"justify-content-md-center " + styles.frame}>
                <Col md={12} className={styles.noPadding}>
                    <Form>
                        <h2 className={styles.title}>APPLY FOR</h2>
                        <h2 className={styles.title + ' ' + styles.marginBottomTitle}>ACCESS</h2>
                        <Form.Group>
                            <Col md={3} className={styles.noPadding + " float-left"}>
                                <Form.Label>SURNAME</Form.Label>
                            </Col>
                            <Col md={9} className={styles.noPadding + " float-left"}>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={surname}
                                    autoComplete="off"
                                    onChange={(e) => setSurName(e.target.value)}
                                /></Col>
                        </Form.Group>
                        <div className="clearfix"></div>
                        <Form.Group>
                            <Col md={3} className={styles.noPadding + " float-left"}><Form.Label>E-MAIL</Form.Label></Col>
                            <Col md={9} className={styles.noPadding + " float-left"}><Form.Control
                                type="text"
                                placeholder=""
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                                spellCheck="false"

                            /></Col>
                        </Form.Group>
                        <div className="clearfix"></div>
                        <Form.Group>
                            <Col md={3} className={styles.noPadding + " float-left"}><Form.Label>COMPANY</Form.Label></Col>
                            <Col md={9} className={styles.noPadding + " float-left"}><Form.Control
                                type="text"
                                placeholder=""
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                                spellCheck="false"

                            /></Col>
                        </Form.Group>
                        <div className="clearfix"></div>
                        <Form.Group>
                            <Col md={3} className={styles.noPadding + " float-left"}><Form.Label>TYPE</Form.Label></Col>
                            <Col md={9} className={styles.noPadding + " float-left"}>
                                <div className={styles.accountTypeContainer}>
                                    <Select
                                        options={options}
                                        placeholder="Select Account Type"
                                        className={["account-types", styles.select].join(" ")}
                                        labelField="name"
                                        onChange={(values) => selectType(values)}
                                    />
                                </div>
                            </Col>
                        </Form.Group>
                        <div className="clearfix"></div>
                        <Form.Group>
                            <Col md={3} className={styles.noPadding + " float-left"}><Form.Label>CONCEPT</Form.Label></Col>
                            <Col md={9} className={styles.noPadding + " float-left"}>
                                <div className={styles.accountTypeContainer}>
                                    <Select
                                        options={conceptOptions}
                                        placeholder="Select Concept"
                                        className={["account-types", styles.select].join(" ")}
                                        labelField="name"
                                        onChange={(values) => selectConcept(values)}

                                    />
                                </div>
                            </Col>
                        </Form.Group>
                        <div className="clearfix"></div>
                        <div className="clearfix"></div>
                        <Form.Group>
                            <Col md={3} className={styles.noPadding + " float-left"}><Form.Label>COMMENTS</Form.Label></Col>
                            <Col md={9} className={styles.noPadding + " float-left"}><Form.Control
                                as="textarea"
                                placeholder=""
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                                spellCheck="false"
                                rows={3}
                            /></Col>
                        </Form.Group>
                        <div className="clearfix"></div>
                        <Form.Group>
                            <Button
                                disabled={loading}
                                className={styles.button + " col-md-12"}
                                onClick={clickHandler}
                            >
                                APPLY
                            </Button>
                            <div className={styles.termsCheckbox}>
                                <Form.Group>
                                    <label className="inputCustomCheckbox form-check">
                                        <input id="tocAccept" type="checkbox" />
                                        <span className="selectedMark"></span>
                                        <label for="tocAccept" className="form-check-label">By creating an account, you agree to J.Lindeberg's terms of use.</label>
                                    </label>
                                </Form.Group>
                            </div>
                            <div className={styles.backButton}>
                                <Link to="/login"><LeftArrow /><span className={styles.backButton}>Back to Login</span></Link>
                            </div>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container >
    )
}

export default Register
