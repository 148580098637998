import React, { useContext, useState, useEffect } from 'react';
import Header from "../components/common/Header";
import Modal from "react-bootstrap/Modal";
import { useAPI } from "../util/useAPI";
import md5 from 'md5';

/** Bootstrap components */
import Button from "react-bootstrap/Button";

/** Scss */
import styles from "./DefaultLayout.module.scss";
import "./DefaultLayout.module.scss";
import Footer from '../components/common/Footer';

function DefaultLayout({ children }) {
  const [showModal, setShowModal] = useState(false);
  const [hashToc, setHashToc] = useState("");

  const [{ data: getToc }, getTocAPIHandler] = useAPI(
    {
      url: "/accounts/userToc",
      method: "GET",
    },
    { manual: true }
  );

  const [{ data: storedToc }, agreeTocAPIHandler] = useAPI(
    {
      url: "/accounts/userToc",
      method: "POST",
    },
    { manual: true }
  );

  const [{ data: TocResponse }] = useAPI(
    {
      url: "/properties/toc_file",
      method: "GET",
    },
    { manual: false }
  );

  const [{ data: detailMedia }, detailMediaRequestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  useEffect(() => {
    if (getToc && getToc?.data !== hashToc) {
      setShowModal(true);
    }
    else {
      setShowModal(false);
    }
  }, [getToc]);

  useEffect(() => {
    if (storedToc) {
      setShowModal(false);
    }
  }, [storedToc]);

  const closeModelHandler = () => {
    setShowModal(false);
  }
  function agreeHandler() {
    agreeTocAPIHandler({
      data: {
        tocContent: hashToc
      },
    });
  }

  const [tocFile, setTocFile] = useState([]);
  useEffect(() => {
    if (TocResponse && TocResponse.data) {
      let queryString = JSON.parse(TocResponse.data.value);
      setHashToc(md5(queryString.mediaid+queryString.updated));
      getTocAPIHandler();
      detailMediaRequestHandler({
        url: "/media/" + queryString.mediaid+'/null',
      });
    }
  }, [TocResponse]);

  useEffect(() => {
    if (detailMedia) {
      setTocFile(detailMedia.data);
    }
  }, [detailMedia]);


  return (
    <>
      <Header />
      {children}
      <Modal className={styles.modal} show={showModal} onHide={closeModelHandler} animation={false}>
        <Modal.Body> <iframe className={styles.tocIframe} src={tocFile.multimediaResource} /></Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button type="button" className={styles.modalButton} onClick={agreeHandler}>AGREE</Button>
          <Button type="button" className={styles.modalButton + " " + styles.modalFooterButton} onClick={closeModelHandler}>CANCEL</Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  )
}

export default DefaultLayout
