import React from "react";

class ErrorBoundry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ERROR ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1 className="mt-5 text-center">Something went wrong! </h1>
          <p className="mt-5 text-center">
            System error occured, please reload the page and if error persists,
            please report your query to support@jlindeberg.se
          </p>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundry;
