import React, { useContext, useEffect } from "react";
import { useAPI } from "../../util/useAPI";

/** Bootstrap components */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "react-bootstrap/Image";

/** Image assets */
import Logo from "../../assets/images/logo.svg";

/** CSS */
import styles from "./Header.module.scss";

/** Related Components */
import MySelectionButton from "../selection/MySelectionButton";
import KeywordSearchAnimated from "../search/KeywordSearchAnimated";
import MyOrderButton from "../order/MyOrderButton";
import LogoutButton from "../common/LogoutButton";
import { UserContext } from "../../util/userContext";


function Header() {

  const { user, setUser } = useContext(UserContext);
  const [{ data: selectionResponse }, selectionHandler] = useAPI(
    {
      url: "/selections",
      method: "GET",
    },
    { manual: false }
  );
  
  useEffect(() => {
    if (selectionResponse && selectionResponse.data) {
      const selectionIds = selectionResponse.data.map((selectItem, index) => {
        return selectItem.id;
      });
      setUser((currentUser) => ({
        ...currentUser,
        ...{
          selectionIds: selectionIds,
          hasProducts: selectionResponse?.meta?.hasProducts ?? false
        }
      }))
    }
  }, [selectionResponse]);
  
  return (
    <header>
      <div>
        <Row className="mr-0">
          <Col md={4} sm={12} className="mb-1 pb-4">
            <Img
              src={Logo}
              className={styles.logo}
              alt="Jlindeberg Logo"
              onClick={() => {
                window.location.href = "/welcome";
              }}
            />
          </Col>

          <Col lg={8} md={12} sm={12} className="mt-lg-0 mt-md-3 mt-sm-3 mt-xs-3 d-none d-sm-block">
            <Row className={styles.headerRight}>
              <KeywordSearchAnimated />
              <MySelectionButton />
              <MyOrderButton />
              <LogoutButton className={styles.logoutButton + " ml-2"} />
            </Row>
          </Col>
          <Col sm={12} className="mt-5 d-block d-sm-none"><KeywordSearchAnimated expandSearch={true} /></Col>
        </Row>
      </div>
    </header>
  );
}

export default Header;
