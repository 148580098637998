import React, { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { UserContext } from "../../util/userContext";
import { useAPI } from "../../util/useAPI";
import { MessageContext } from "../../util/messageContext";

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

/** Scss */
import styles from "./Login.module.scss";

import BottomLogo from '../../assets/images/logo.png';

function Login({ loginHeader = '<p>ASSET </p><p>BANK</p>', tocBody = "" }) {
  const { setUser } = useContext(UserContext);
  const { setMessage } = useContext(MessageContext);
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [{ data, loading, error }, loginAPIHandler] = useAPI(
    {
      url: "/auth/login",
      method: "POST",
    },
    { manual: true }
  );

  useEffect(() => {
    if (data && data.data) {
      setUser(data.data);
      localStorage.setItem("user", JSON.stringify(data.data));
      history.push("/welcome");
    }
    if (error) {
      setMessage({ title: 'Unable to Login', message: 'Invalid Login Credentails!', type: 'toast' })
    }
  }, [data, error]);

  function loginClickHandler() {
    localStorage.clear();
    loginAPIHandler({
      data: {
        username: username,
        password: password,
      },
    });
  }

  function tocClickHandler() {
    setMessage({
      type: "modal",
      title: "USER TERMS & CONDITIONS",
      message: tocBody
    })
  }


  return (
    <Container>
      <Row className={"justify-content-md-center " + styles.frame}>
        <Col md={12} className={styles.noPadding}>
          <Form>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: loginHeader }}></h2>
            <img className={styles.logo} width="50px" src={BottomLogo} alt="JLindeberg" />
            <Form.Group>
              <Col md={3} className={styles.noPadding + " float-left"}>
                <Form.Label>USERNAME</Form.Label>
              </Col>
              <Col md={9} className={styles.noPadding + " float-left"}>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={username}
                  autoComplete="off"
                  onChange={(e) => setUsername(e.target.value)}
                /></Col>
            </Form.Group>
            <div className="clearfix"></div>
            <Form.Group>
              <Col md={3} className={styles.noPadding + " float-left"}><Form.Label>PASSWORD</Form.Label></Col>
              <Col md={9} className={styles.noPadding + " float-left"}><Form.Control
                type="password"
                placeholder=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck="false"

              /></Col>
            </Form.Group>
            <Form.Group>
              <Button
                disabled={loading}
                className={styles.loginButton + " col-md-12"}
                onClick={loginClickHandler}
              >
                LOGIN
              </Button>
              <div className={styles.forgotPasswordText}>
                <Link to="/forgotPassword">Forgot password?</Link>
              </div>
              <div className={styles.tocLink}>
                <a onClick={tocClickHandler}>Terms of use</a>
              </div>
              <div className={styles.registerLink}>
                <Link to="/register">Apply for access</Link>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
