import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import DownloadAllButton from '../buttons/DownloadAllButton';
import ResultContainer from '../search/ResultContainer';
import ShareSelectionHeader from './ShareSelectionHeader';


const SelectionShareView = () => {

    return (
        <>
            <ShareSelectionHeader></ShareSelectionHeader>
            <div className="mt-3">
                <Container>
                    <DownloadAllButton></DownloadAllButton>
                </Container>                
                <ResultContainer mode="selection"></ResultContainer>
            </div>  
            
        </>
        
    );

}

export default SelectionShareView;