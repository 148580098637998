/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classnames from "classnames";
import Select from "react-dropdown-select";

import styles from "./DownloadTemplates.module.scss";

const DownloadTemplates = (props) => {
  const handleDropdownChange = (values) => {
    window.location = `${baseUrl}/${values[0].mediaId}/${values[0].templateId}/${values[0].classification}${
      props.fileName ? "/" + props.fileName : ""
    }`;
  };
  const options = props.templates?.[props.item.categoryId]?.[
    props.item.classification
  ]?.map((item) => {
    return {
      _id: `${props.item.id}-${props.item.categoryId}-${item.templateId}`,
      filename: props.item.title,
      name: item.name,
      mediaId: props.item.id,
      templateId: item.templateId,
      categoryId: props.item.categoryId,
      classification: props.item.classification,
    };
  });

  const baseUrl = `${process.env.REACT_APP_MEDIAPORTAL_API_URL}/media/download`;

  switch (props.type) {
    case "menu":
      return (
        <>
          <div className={classnames(styles.downloadMenu, "position-absolute")}>
            <ul>
              {props.templates?.[props.item.categoryId]?.[
                props.item.classification
              ]?.map((template, key) => {
                return (
                  <li key={key}>
                    <a
                      href={`${baseUrl}/${props.item.id}/${
                        template.templateId
                      }/${props.item.classification}${
                        props.fileName ? "/" + props.fileName : ""
                      }`}
                      target="_self"
                    >
                      {template.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      );
      break;
    case "dropdown":
      return (
        <>
          <div className={classnames(styles.dropdownContainer)}>
            <Select
              options={options}
              additionalProps={{
                categoryId: props.item.categoryId,
                classification: props.item.classification,
              }}
              placeholder="Download"
              className={styles.dropdown}
              labelField="name"
              contentRenderer={(object) => {
                return "Download";
              }}
              onChange={(values) => handleDropdownChange(values)}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => option.name}
            />
          </div>
        </>
      );
      break;

    default:
      return (
        <>
          <div className={classnames(styles.inlineDownloadList)}>
            <ul>
              {props.templates !== null
                ? props.templates?.[props.item.categoryId]?.[
                    props.item.classification
                  ]?.map((template, key) => {
                    return (
                      <li key={key}>
                        <a
                          href={`${baseUrl}/${props.item.id}/${template.templateId}/${props.item.classification}${
                            props.fileName ? "/" + props.fileName : ""
                          }`}
                          title={template.name}
                          target="_self"
                        >
                          {template.name}
                        </a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </>
      );
      break;
  }
};

export default DownloadTemplates;
