function searchHandler(
  filters,
  resetPropertyFilters = true,
  resetCategoryFilter = false
) {
  let filterQuery = "";

  if (filters) {
    //Generate Limit
    filterQuery += "?limit=12";

    //Seperator
    filterQuery += "&";

    //Generate the category filter
    if (filters.categoryId) {
      filterQuery += "categoryId=" + filters.categoryId;
    }

    //Generate the keyword filter
    if (filters.keyword) {
      filterQuery += "&query=" + filters.keyword;
    }

    //Generate property filters
    if (filters.propertyFilters) {
      if (filters.propertyFilters.length > 0) {
        filters.propertyFilters.forEach((property) => {
          if (property.values.length > 0) {
            property.values.forEach((propertyValue) => {
              if (propertyValue.selected === true) {
                filterQuery +=
                  "&properties[" +
                  property.systemName +
                  "][]=" +
                  propertyValue.name;
              }
            });
          }
        });
      }
    }

    //Sorting
    if (filters.sort) {
      filterQuery += "&sort=" + filters.sort;
    }
  }

  return filterQuery;
}

export default searchHandler;
