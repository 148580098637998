import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/** CSS */
import styles from "./MySelectionButton.module.scss";
import { UserContext } from "../../util/userContext";

function MySelectionButton() {
  const { user, setUser } = useContext(UserContext);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (user && user.selectionCount !== undefined) {
      setCount(user.selectionCount);
    } else {
      setCount(0);
    }
  }, [user, user.selectionCount]);

  return (
    <>
      <Link
        id="myselectionButton"
        to="/selection"
        className={styles.myselectionButton + " ml-2"}
      >
        ({count}) {count > 1 ? "IN MY SELECTED ASSETS" : "IN MY SELECTED ASSET"}
      </Link>
    </>
  );
}

export default MySelectionButton;
