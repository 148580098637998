import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../util/userContext";
import { useAPI } from "../../util/useAPI";
import classnames from "classnames";

/** CSS */
import styles from "./ShareListButton.module.scss";


function ShareListButton() {

  const [show, setShow] = useState(false);
  const [hash, setHash] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {
      method: "GET",
      url: '/selections/share'
    },
    { manual: true }
  );


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const share = () => {
    setUser({...user, ...{loading : true}});
    requestHandler({});
  }

  useEffect(() => {
    setUser({...user, ...{loading : false}});
  }, [])

  useEffect(() => {
    setUser({...user, ...{loading : false}});
    if(data && data.data) {
      setHash(process.env.REACT_APP_BASE_URL+'/selection/share/'+data.data);
      handleShow();
    }
  }, [data])

  const copy = () => {
    navigator.clipboard.writeText(hash);
    handleClose();
  }

  return (
    <>
      <Button href={void(0)} className={styles.buttonStyle} onClick={() => {share()}}>Share All</Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="l"
      >
        <Modal.Header closeButton>
          <Modal.Title id="share-link" className={styles.modalTitle}>Share Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea className={classnames("form-control", styles.shareSelectionUrl)} value={hash}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
            <Button variant="primary" onClick={copy}>Copy to clipboard</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

export default ShareListButton;
