import { useState, useEffect } from 'react';

const useInfiniteScroll = (callback, intercept) => {

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (intercept) {
      handleScroll();
    }
  }, [intercept]);

  useEffect(() => {
    if (isFetching) {
      callback();
      setIsFetching(false);
    }
  }, [isFetching]);

  function handleScroll() {
    setIsFetching(intercept);
  }

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;