import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faVideo,
  faFileAudio,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Img from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import DownloadMenuButton from "../buttons/DownloadMenuButton";
import ExpandButton from "../buttons/ExpandButton";
import AddToSelectionButton from "../buttons/AddToSelectionButton";

import styles from "./Thumbnail.module.scss";
import { UserContext } from "../../util/userContext";
import { FilterContext } from "../../util/filterContext";
import searchHandler from "../../util/searchHandler";

const Thumbnail = (props) => {
  const { user, setUser } = useContext(UserContext);
  const { filter, setFilter } = useContext(FilterContext);
  const [highlight, setHighlight] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showViewButton, setShowViewButton] = useState(false);

  const totalColumns = process.env.REACT_APP_GRID_COLUMNS;
  const modes = props.modes;

  function getIconFromClasssification($classification) {
    switch ($classification) {
      case "image":
        return faImage;
        break;

      case "video":
        return faVideo;
        break;

      case "audio":
        return faFileAudio;
        break;

      case "document":
        return faFilePdf;
        break;

      default:
        break;
    }
  }

  const expandHandler = (e) => {
    history.push(
      location.pathname + searchHandler(filter) + "&mediaId=" + props.item.id
    );

    let previewContainer = {};
    console.log(props);
    previewContainer[props.rowId] = { show: true, mediaId: props.item.id };
    props.setPreviewContainer(previewContainer);

    props.setExpandedItem(props.item);
    props.setCurrentIndex(props.currentIndex);
    props.focusHandler();
  };

  useEffect(() => {
    if (user.selectionIds) {
      if (user.selectionIds.includes(props.item.id)) {
        setHighlight(true);
      } else {
        setHighlight(false);
      }
    }
  }, [user.selectionIds, props.item.id]);

  const handleChange = (e, mediaId) => {
    const checked = e.target.checked;
    const current = { ...props.orderMediaIds };
    if (checked) {
      current[mediaId] = true;
    } else {
      current[mediaId] = false;
    }
    props.setOrderMediaIds(current);
  };

  return (
    <>
      <Col
        data-row-id={props.rowId}
        className={classnames(
          "col-12",
          "col-sm-12",
          modes[props.gridMode]
            ? "col-md-" + totalColumns / modes[props.gridMode]
            : "col-md-4",
          styles.thumbnailContainer,
          props.gridMode === "SMALL" ? "mb-5" : "mb-3"
        )}
        onMouseEnter={() => setShowViewButton(true)}
        onMouseLeave={() => setShowViewButton(false)}
      >
        <div className={classnames("d-flex", "flex-column expand")}>
          <div
            className={classnames(
              styles.imageTitleContainer,
              highlight ? styles.highlitedBorder : ""
            )}
          >
            <div
              className={classnames(
                "d-flex",
                "flex-column",
                // "justify-content-center",
                "text-center",
                styles.thumb,
                props.gridMode === "SMALL" ? styles.smallGrid : "",
                "position-relative"
              )}
            >
              <div
                className={classnames("position-relative", styles.imgContainer)}
                onClick={(e) => expandHandler(e)}
              >
                <Img
                  src={props.item.remoteFile}
                  alt={props.item.title}
                  className={classnames(
                    "align-self-center",
                    "img-responsive",
                    styles.thumbImage,
                    styles.smallGridHeight
                  )}
                />
                {props.item.classification === "video" ||
                props.item.classification === "audio" ? (
                  <>
                    <div className={styles.playIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="currentColor"
                        className="bi bi-play-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                      </svg>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div
                className={classnames(
                  styles.buttonContainer,
                  props.gridMode === "SMALL" ? styles.adjustTopPosition : null,
                  props.mode === "order" ? styles.buttonAlign : ""
                )}
              >
                {/* <div
                  className={classnames("position-absolute", styles.iconExpand)}
                >
                  <ExpandButton
                    classId="icon"
                    {...props}
                    handler={expandHandler}
                  />
                </div> */}
                {user && user.username ? (
                  props.mode === "order" ? (
                    <div className={classnames("position-absolute")}>
                      <label className={styles.inputCustomCheckbox}>
                        <input
                          checked={
                            (props.orderMediaIds &&
                              props.orderMediaIds[props.item.id]) ??
                            false
                          }
                          type="checkbox"
                          value={props.item.id}
                          onClick={(e) => handleChange(e, props.item.id)}
                        />
                        <span className={styles.selectedMark}></span>
                      </label>
                    </div>
                  ) : (
                    <div
                      className={classnames(
                        "position-absolute",
                        styles.iconAddCollection
                      )}
                    >
                      <AddToSelectionButton {...props} classId="icon" />
                    </div>
                  )
                ) : (
                  ""
                )}

                <div
                  className={classnames(
                    "position-absolute",
                    styles.iconDownload
                  )}
                >
                  <DownloadMenuButton classId="icon" {...props} />
                </div>
              </div>
            </div>
            {showViewButton && props.mode === "search" ? (
              <div className={classnames(styles.viewDetailButton)}>
                <button onClick={(e) => expandHandler(e)}>View detail</button>
              </div>
            ) : null}
            <div
              className={classnames(
                props.gridMode === "SMALL"
                  ? styles.thumbTitleFullWidth
                  : styles.thumbTitle,
                "py-3"
              )}
            >
              {/* <FontAwesomeIcon
                icon={getIconFromClasssification(props.item.classification)}
              /> */}
              {props.item.title}
            </div>
            {filter?.categoryId === 2 && (
              <div className={styles.styleName}>
                {
                  props?.item?.properties?.filter(
                    (x) => x.name === "Style Name"
                  )[0]?.value
                }
              </div>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default React.memo(Thumbnail);
