import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  Fragment,
} from "react";
import Container from "react-bootstrap/Container";
import Thumbnail from "./Thumbnail";
import styles from "./ResultContainer.module.scss";
import Row from "react-bootstrap/Row";
import Img from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import classnames from "classnames";
import Loader from "../../assets/images/preloader.svg";
import SearchControls from "./SearchControls";
import { FilterContext } from "../../util/filterContext";
import searchHandler from "../../util/searchHandler";
import PreviewItem from "./PreviewItem";
import DetailViewMobile from "./DetailViewMobile";
import useResult from "../../util/useResult";
import usePreview from "../../util/usePreview";
import { useInView } from "react-intersection-observer";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ResultContainer = (props) => {
  //Configuration
  const totalColumns = process.env.REACT_APP_GRID_COLUMNS;
  const modes = {
    MEDIUM: parseInt(process.env.REACT_APP_VIEW_MODE_MEDIUM_ITEMS),
    SMALL: parseInt(process.env.REACT_APP_VIEW_MODE_SMALL_ITEMS),
    LARGE: parseInt(process.env.REACT_APP_VIEW_MODE_LARGE_ITEMS),
  };

  const [ref, inView] = useInView({
    threshold: 0,
  });

  const {
    setTotalRecords,
    templates,
    mobile,
    loading,
    getTemplateLoading,
    setMediaItems,
    mediaItems,
    setOffset,
    offset,
    setUser,
    user,
    setIsNoResults,
    isNoResults,
    setFilterQuery,
    setPreviewContainer,
    previewContainer,
    totalRecords,
  } = useResult({
    mode: props.mode,
    intercept: inView,
    orderId: props.orderId,
  });
  const {
    handleClose,
    handleShow,
    show,
    setExpandedItem,
    expandedItem,
    setCurrentIndex,
    currentIndex,
  } = usePreview();
  //State Variables
  const [gridMode, setGridMode] = useState(user.gridMode ?? "MEDIUM");
  const [showMobile, setShowMobile] = useState(false);
  const [lastRow, setLastRow] = useState(0);
  const [removedItem, setRemovedItem] = useState(null);
  const { filter } = useContext(FilterContext);
  const prevFilter = usePrevious(filter);

  let columns = modes[gridMode];

  //Set grid mode change to the context
  useEffect(() => {
    setUser({ ...user, ...{ gridMode: gridMode } });
  }, [gridMode]);

  //Update filterQuery using search handler when there is change in filter
  useEffect(() => {
    if (prevFilter !== filter) {
      setFilterQuery(searchHandler({ ...filter }));
      setPreviewContainer({});
    }
  }, [filter]);

  //Update result loading state to the context
  useEffect(() => {
    setUser({
      ...user,
      ...{ resultLoading: loading, totalRecords: 0 },
    });
  }, [loading]);

  //Clear Media items when there is no selected items
  useEffect(() => {
    if (
      user &&
      user.clearSelection &&
      props.mode === "selection" &&
      user.selectionCount == 0
    ) {
      setMediaItems([]);
    }
  }, [user]);

  //Remove Items from selection
  useEffect(() => {
    let items = [];
    if (removedItem !== null && props.mode === "selection") {
      items = mediaItems.filter((item) => item.id != removedItem);
      setMediaItems(items);
    }
  }, [removedItem]);

  //Set last rowId to display previw container
  useEffect(() => {
    setLastRow(Math.abs(Math.ceil(totalRecords / columns)) - 1);
    setUser({
      ...user,
      ...{ totalRecords: totalRecords },
    });
  }, [totalRecords]);

  //Update the View to use Modal view for the
  useEffect(() => {
    if (Object.keys(previewContainer).length > 0) {
      setShowMobile(true);
    } else {
      setShowMobile(false);
    }
  }, [previewContainer]);

  if (props.mode === "selection" || props.mode === "order") {
    modes[user.gridMode] = parseInt(process.env.REACT_APP_MY_SELECTION_COLUMNS);
    columns = parseInt(process.env.REACT_APP_MY_SELECTION_COLUMNS);
  }

  //PreviewContainer reference use to focus
  const inputRef = useRef(null);
  const focusHandler = () => {
    inputRef.current?.focus();
  };
  const callbackRef = (node) => {
    if (node) {
      node.focus();
    }
  };

  //add all order medias

  useEffect(() => {
    if (props.mode === "order") {
      let orderMediaIds = {};
      mediaItems.forEach((item) => {
        if (!orderMediaIds[item.id]) {
          orderMediaIds[item.id] = false;
        }
      });
      props.setOrderMediaIds(orderMediaIds);
    }
  }, [mediaItems]);

  return (
    <React.Fragment>
      {props.mode === "search" ? (
        <SearchControls
          {...props}
          gridMode={gridMode}
          setGridMode={setGridMode}
          totalRecords={totalRecords}
        />
      ) : (
        <></>
      )}
      <Container
        className={[
          classnames(styles.resultContainer),
          props.mode === "search" ? " py-5" : "",
        ]}
      >
        {props.mode === "order" && isNoResults && !mediaItems.length ? (
          <Col md={12} className="px-0">
            No assets connected to the order.
          </Col>
        ) : null}
        <Row
          className={
            props.mode === "search"
              ? "d-flex flex-wrap"
              : styles.resultTop + " d-flex flex-wrap "
          }
        >
          {mediaItems.map((item, key) => {
            let rowId = Math.trunc(key / columns);
            return (
              <Fragment key={key}>
                {key % columns === 0 &&
                key >= 0 &&
                previewContainer[rowId - 1] &&
                !mobile ? (
                  <Col md={12} className={classnames("full")} data-test="first">
                    <PreviewItem
                      {...props}
                      setExpandedItem={setExpandedItem}
                      mediaItems={mediaItems}
                      mode={modes}
                      currentIndex={currentIndex}
                      templates={templates}
                      expandedItem={expandedItem}
                      show={show}
                      detailViewHandler={handleShow}
                      setPreviewContainer={setPreviewContainer}
                      handleClose={handleClose}
                      setCurrentIndex={setCurrentIndex}
                      setRemovedItem={setRemovedItem}
                      callbackRef={callbackRef}
                    />
                  </Col>
                ) : null}

                <Thumbnail
                  {...props}
                  rowId={rowId}
                  item={item}
                  setPreviewContainer={setPreviewContainer}
                  setCurrentIndex={setCurrentIndex}
                  currentIndex={key}
                  setExpandedItem={setExpandedItem}
                  show={show}
                  modes={modes}
                  gridMode={gridMode}
                  handleShow={handleShow}
                  templates={templates}
                  setRemovedItem={setRemovedItem}
                  focusHandler={focusHandler}
                />
              </Fragment>
            );
          })}
          {(previewContainer[lastRow] || isNoResults) && !mobile ? (
            <Col md={12} className={classnames("full")} data-test="second">
              <PreviewItem
                {...props}
                setExpandedItem={setExpandedItem}
                mediaItems={mediaItems}
                mode={modes}
                currentIndex={currentIndex}
                templates={templates}
                expandedItem={expandedItem}
                show={show}
                detailViewHandler={handleShow}
                setPreviewContainer={setPreviewContainer}
                handleClose={handleClose}
                setCurrentIndex={setCurrentIndex}
                setRemovedItem={setRemovedItem}
                callbackRef={callbackRef}
              />
            </Col>
          ) : null}

          <input type="button" className={styles.hiddenElement} ref={ref} />
        </Row>
        <Row>
          <Col className={classnames("col-12", "text-center")}>
            {(() => {
              if (loading || getTemplateLoading) {
                return (
                  <Img
                    src={Loader}
                    width="60"
                    className={classnames("mt-4")}
                    alt="Preloader"
                  />
                );
              } else {
                return <span></span>;
              }
            })()}
          </Col>
        </Row>
      </Container>

      {mobile ? (
        <DetailViewMobile
          {...props}
          setExpandedItem={setExpandedItem}
          mediaItems={mediaItems}
          mode={modes}
          currentIndex={currentIndex}
          templates={templates}
          expandedItem={expandedItem}
          show={showMobile}
          detailViewHandler={handleShow}
          setPreviewContainer={setPreviewContainer}
          handleClose={handleClose}
          setCurrentIndex={setCurrentIndex}
          setRemovedItem={setRemovedItem}
        />
      ) : null}
    </React.Fragment>
  );
};

export default ResultContainer;
