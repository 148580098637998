import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/Col";
import classnames from "classnames";
import Modal from "react-bootstrap/Modal";
import styles from "./PreviewItem.module.scss";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import DownloadTemplates from "../media/DownloadTemplates";
import { useAPI } from "../../util/useAPI";
import Img from "react-bootstrap/Image";
import Loader from "../../assets/images/preloader.svg";
import { UserContext } from "../../util/userContext";
import CopyUrlButton from "../buttons/CopyUrlButton";
import { FilterContext } from "../../util/filterContext";
import { useHistory, useLocation } from "react-router-dom";
import searchHandler from "../../util/searchHandler";
import AddToSelectionButtonDetailView from "../buttons/AddToSelectionButtonDetailView";
import { ReactComponent as Arrow } from '../../assets/images/prev.svg';
import { ReactComponent as Close } from '../../assets/images/times-light.svg'
const DetailViewMobile = (props) => {
  const [item, setItem] = useState(props.expandedItem);
  const [index, setIndex] = useState(0);
  const { user, setUser } = useContext(UserContext);
  const { filter, setFilter } = useContext(FilterContext);
  const history = useHistory();
  const location = useLocation();
  const modes = {
    MEDIUM: process.env.REACT_APP_VIEW_MODE_MEDIUM_ITEMS,
    SMALL: process.env.REACT_APP_VIEW_MODE_SMALL_ITEMS,
    LARGE: process.env.REACT_APP_VIEW_MODE_LARGE_ITEMS,
  }
  const previewContainerRef = useRef(null);

  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  function getMedia() {
    requestHandler({
      url: "/media/" + props.expandedItem.id,
    });
  }

  useEffect(() => {
    if (props.expandedItem) {
      setItem(null);
      getMedia();
    }
  }, [props.expandedItem]);

  useEffect(() => {
    if (data) {
      setItem(data.data);
    }
  }, [data]);

  useEffect(() => {
    setIndex(props.currentIndex);
  }, [props.currentIndex]);

  const onExited = () => {
    setItem(null);
    props.setExpandedItem(null);
  };

  const next = () => {
    let nextIndex = parseInt(index) + 1;
    setItem(null);
    setIndex(nextIndex);
    props.setCurrentIndex(nextIndex);
    props.setExpandedItem(props.mediaItems[nextIndex]);
    let previewContainer = {}
    let row = Math.trunc(nextIndex / modes[user.gridMode]);
    previewContainer[row] = { show: true, mediaId: props.mediaItems[nextIndex].id }
    props.setPreviewContainer(previewContainer);
    scrollToPreview();
    history.push(
      location.pathname +
      searchHandler(filter) +
      "&mediaId=" +
      props.mediaItems[nextIndex].id
    );
  };

  function prev() {
    let prevIndex = parseInt(index) - 1;
    setItem(null);
    setIndex(prevIndex);
    props.setCurrentIndex(prevIndex);
    props.setExpandedItem(props.mediaItems[prevIndex]);
    let previewContainer = {}
    let row = Math.trunc(prevIndex / modes[user.gridMode]);
    previewContainer[row] = { show: true, mediaId: props.mediaItems[prevIndex].id }
    props.setPreviewContainer(previewContainer);
    scrollToPreview();
    history.push(
      location.pathname +
      searchHandler(filter) +
      "&mediaId=" +
      props.mediaItems[prevIndex].id
    );
  }

  function close() {
    setItem(null);
    props.setExpandedItem(null);
    props.setPreviewContainer({});
  }

  const scrollToPreview = () => previewContainerRef.current.scrollIntoView();

  function preview() {
    switch (item.classification) {
      case "video":
        return (
          <video
            id="videoplayer"
            width="100%"
            src={item.multimediaResource}
            controls
            controlsList="nodownload"
            type="video/mp4"
            alt={item.title}
            poster={item.remotePreviewFile}
            className="video-player"
            preload="none"
          >
            <p>
              Your browser doesn't support HTML5 video. Here is a{" "}
              <a href={item.multimediaResource}>link to the video</a> instead.
            </p>
          </video>
        );
        break;

      default:
        return (
          <Image
            className={classnames("img-responsive", "img-fluid", styles.previewImgae)}

            src={item.remotePreviewFile}
            alt={item.title}
            id="previewImg"
          />
        );
        break;
    }
  }

  return (
    <>
      <Modal
        fullscreen={true}
        show={props.show}
        onHide={props.handleClose}
        onExited={onExited}
        className={classnames(styles.modalContent)}
      >
        <Modal.Body
          className={classnames(styles.previewContainer, "position-relative", "m-2")}
        >
          {item ? (
            <>
              <a className={styles.close} onClick={close}><Close /></a>
              <Row ref={previewContainerRef}>

                <Col
                  className={classnames(
                    "col-12",
                    "col-sm-12",
                    "col-md-6",
                    styles.previewColumns,
                    styles.zoomCursor
                  )}
                // onClick={props.detailViewHandler}
                >
                  <div
                    className={classnames(
                      "position-relative",
                      styles.transparentBackground
                    )}
                  >
                    {preview()}
                  </div>
                </Col>
                <Col
                  className={classnames(
                    "col-12",
                    "col-sm-12",
                    "col-md-6",
                    styles.previewColumns,
                  )}
                >

                  {user && user.username ? (
                    <div className={classnames(styles.topLinks)}>
                      <AddToSelectionButtonDetailView setRemovedItem={props.setRemovedItem} item={item} classId="iconBig" />
                      <CopyUrlButton classId="iconBig" />
                    </div>
                  ) : (
                    ""
                  )}
                  <h4 className={classnames(styles.mediaTitle,)}>
                    {item.title}
                  </h4>
                  {item && item.properties ? (
                    item.properties.map((item, index) => {
                      if (item.value) {
                        return (
                          <>
                            <Row className="mb-1" key={index}>
                              <Col
                                className={classnames(
                                  "col-12",
                                  "col-sm-12",
                                  "col-md-5",
                                  styles.propertyTitle
                                )}
                              >
                                {item.name}
                              </Col>
                              <Col
                                className={classnames(
                                  "col-12",
                                  "col-sm-12",
                                  "col-md-7",
                                  styles.propertyValue
                                )}
                              >
                                {item.value}
                              </Col>
                            </Row>
                          </>
                        );
                      }
                    })
                  ) : (
                    <>
                      <Col className="col-12 col-sm-12 col-md-12">
                        <Img
                          src={Loader}
                          width="60 "
                          height="300"
                          className={classnames(
                            "mt-4",
                            "mx-auto",
                            "d-block",
                            "my-auto"
                          )}
                          alt="Preloader"
                        />
                      </Col>
                    </>
                  )}
                  <div className={styles.downloadTemplates}>
                    <DownloadTemplates
                      className="float-left"
                      templates={props.templates}
                      item={item}
                      type="dropdown"
                    />
                  </div>
                </Col>
              </Row>

            </>
          ) : (
            <Img
              src={Loader}
              width="60 "
              height="300"
              className={classnames("mt-4", "mx-auto", "d-block", "my-auto")}
              alt="Preloader"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailViewMobile;
