import React, { useContext } from "react";
import classnames from "classnames";

/** CSS */
import styles from "./ViewModeButton.module.scss";

/** Bootstrap components */

import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import { faTh } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../util/userContext";

function ViewModeButton({ gridMode, setGridMode = () => { } }) {
  const { user } = useContext(UserContext);

  return (
    <span className="">
      <Button
        id="mediumGridView"
        className={classnames(
          styles.buttonStyle,
          gridMode === "MEDIUM" ? styles.highlighted : ""
        )}
        onClick={() => setGridMode("MEDIUM")}
        size="sm"
      >
        <FontAwesomeIcon size="lg" icon={faThLarge} />
      </Button>{" "}
      <Button
        id="smallGridView"
        className={classnames(
          styles.buttonStyle,
          gridMode === "SMALL" ? styles.highlighted : ""
        )}
        onClick={() => setGridMode("SMALL")}
        size="sm"
      >
        <FontAwesomeIcon size="lg" icon={faTh} />
      </Button>
    </span>
  );
}

export default ViewModeButton;
