import React, { useEffect, useContext } from "react";
import Category from "./Category";
import WelcomeMedia from "./WelcomeMedia";

/** CSS */
import styles from "./Welcome.module.scss";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import { FilterContext } from "../../util/filterContext";

function Welcome(props) {
  const { filter, setFilter } = useContext(FilterContext);
  useEffect(() => {
    setFilter({ ...filter, ...{ keyword: "" } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <Container>
          <Row className={styles.welcomeRow}>
            <WelcomeMedia />
            <Category showSlide={false} welcome={true} />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Welcome;
