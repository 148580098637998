import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import styles from "./AddToSelectionButtonDetailView.module.scss";
import { UserContext } from "../../util/userContext";
import { useAPI } from "../../util/useAPI";
import Loader from "../common/Loader";
import BaseButton from "./BaseButton";
import { ReactComponent as Plus } from "../../assets/images/plus-light.svg";
import { ReactComponent as Minus } from "../../assets/images/minus-light.svg";

const Component = (props) => {
  const { user, setUser } = useContext(UserContext);
  const [selected, setSelected] = useState(false);
  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {},
    { manual: true }
  );

  useEffect(() => {
    let selection;
    if (!user.selectionIds) {
      selection = {
        selectionCount: 0,
        selectionIds: [],
        loading: loading,
      };
    } else {
      if (user.selectionIds.includes(props.item.id)) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [user, props.item.id]);

  const add = (id) => {
    setUser({ ...user, ...{ loading: true } });
    requestHandler({
      method: "POST",
      url: "/selections",
      data: {
        id: id,
      },
    });
  };

  const remove = (id) => {
    setUser({ ...user, ...{ loading: true } });
    requestHandler({
      url: "/selections/" + id,
      method: "DELETE",
    });
  };

  useEffect(() => {
    if (data) {
      setUser((current) => ({...current, selectionIds: data?.data?.mediaIds, selectionCount: data?.data?.count, hasProducts: data?.data?.hasProducts, loading: false}))
    }
  }, [data]);

  return (
    <React.Fragment>
      {selected ? (
        <a
          id={"select_media_" + props.item.id}
          href={void 0}
          data-id={selected}
          onClick={(e) => {
            remove(props.item.id);
            e.stopPropagation();
          }}
          title="Remove from My Selected Asset"
          className={classnames(styles.remove)}
        ><Minus /> Remove from selected assets</a>
      ) : (
        <a
          id={"unselect_media_" + props.item.id}
          href={void 0}
          data-id={selected}
          onClick={(e) => {
            add(props.item.id);
            e.stopPropagation();
          }}
          title="Add to My Selected Asset"
          className={classnames(styles.add)}
        > <Plus /> Add to selected assets</a>
      )}
    </React.Fragment>
  );
};

var AddToSelectionButtonDetailView = BaseButton(Component);

export default AddToSelectionButtonDetailView;
