import React, { useContext, useState, useEffect } from "react";
import { useAPI } from "../../util/useAPI";

/** CSS */
import styles from "./WelcomeMedia.module.scss";
import Row from "react-bootstrap/Row";
import Img from "react-bootstrap/Image";

function WelcomeMedia() {
  const [{ data: welcomeMediaResponse }] = useAPI(
    {
      url: "/properties/welcome_media",
      method: "GET",
    },
    { manual: false }
  );

  //Search specific media ids (Use in selection main view from the URL)
  const [{ data: detailMedia }, detailMediaRequestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  const [media, setMedia] = useState([]);
  useEffect(() => {
    if (welcomeMediaResponse && welcomeMediaResponse.data) {
      let queryString = JSON.parse(welcomeMediaResponse.data.value);
      if (queryString.class === 'Video') {
        detailMediaRequestHandler({
          url: "/media/" + queryString.mediaid,
        });
      } else {
        detailMediaRequestHandler({
          url: "/media/" + queryString.mediaid + "/NULL",
        });
      }
    }
  }, [welcomeMediaResponse]);

  useEffect(() => {
    if (detailMedia) {
      setMedia(detailMedia.data);
    }
  }, [detailMedia]);

  // const [controls, setControls] = useState(false);
  // const videoHandler = () => {
  //   setControls(true);
  // };

  if (media.classification == "video") {
    return (
      <>
        <Row className={styles.welcomeStyle}>
          <video
            id="videoplayer"
            src={media.multimediaResource}
            //onClick={videoHandler}
            controls
            autoPlay
            loop
            muted
            controlsList="nodownload"
            type="video/mp4"
            alt={media.title}
            poster={media.remotePreviewFile}
            className={"video-player " + styles.mediaStyle}
          >
          </video>
        </Row>
      </>
    );
  }
  else if (media.classification == "image") {
    return (
      <>
        <Row className={styles.mediaStyle}>
          <Img src={media.remotePreviewFile} className={styles.mediaStyle} ></Img>
        </Row>
      </>
    );
  }
  else {
    return (
      <>
      </>
    );
  }

}


export default WelcomeMedia;
