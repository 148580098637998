import classnames from "classnames";
import {default as BootstrapButton} from "react-bootstrap/Button";

import styles from "./Button.module.scss";

function Button({size = "medium", className = "", children, ...rest}) {
    const sizeStyles = styles[size]
  return <BootstrapButton className={classnames(styles.style, sizeStyles, className)} {...rest}>{children}</BootstrapButton>;
}

export default Button;
