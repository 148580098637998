import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

/** Scss */
import styles from "./Register.module.scss";
import { ReactComponent as LeftArrow } from '../../assets/images/leftArrow.svg';

function RegisterAcknowledge() {

    return (
        <Container className="mt-10">
            <Row className={"justify-content-md-center mt-5 " + styles.frame}>
                <Col md={12} className={styles.noPadding}>
                    <Form>
                        <h2 className={styles.title}>SUBMITTED</h2>
                        <h2 className={styles.title + ' ' + styles.marginBottomTitle}></h2>
                        <p>Thank you for applying to J.Lindeberg Asset Bank. Your application is sent to the admin, and your account will be created soon.</p>

                        <div className="clearfix"></div>
                        <Form.Group>


                            <div className={styles.backButton}>
                                <Link to="/login"><LeftArrow /><span className={styles.backButton}>Back to Login</span></Link>
                            </div>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container >
    )
}

export default RegisterAcknowledge
