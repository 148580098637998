import React from 'react'
import styles from "./DownloadSelectionProductInfo.module.scss";

function DownloadSelectionProductInfo({ disable = false }) {
    return (
        <>
            <a href="/api/v1/selections/downloadProductInformation"
                id="downloadCSVButton"
                className={[styles.buttonStyle, disable ? styles.disabled : null].join(" ")}>
                PRODUCT INFORMATION CSV
            </a>
        </>
    );
}

export default DownloadSelectionProductInfo
