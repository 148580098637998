import React, { useState } from "react";
import styles from "./CopyUrlButton.module.scss";
import Toast from "react-bootstrap/Toast";
import BaseButton from "./BaseButton";
import classnames from "classnames";
import { ReactComponent as CopyLink } from "../../assets/images/link.svg";

const Component = (props) => {
  const [show, setShow] = useState(false);
  const copyHandler = () => {
    setShow(true);
    var input = document.body.appendChild(document.createElement("input"));
    input.value = window.location.href;
    input.select();
    document.execCommand("copy");
    input.parentNode.removeChild(input);

  };
  return (
    <>
      <a
        id="copyUrlButton"
        className={classnames()}
        onClick={copyHandler}
        title="Copy link"
      >
        <CopyLink /> Copy link
      </a>
      <Toast
        className={styles.toast}
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
      >
        <Toast.Body>Link copied to clipboard</Toast.Body>
      </Toast>
    </>
  );
}

var CopyUrlButton = BaseButton(Component);

export default CopyUrlButton;
