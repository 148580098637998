import React, { useContext, useState, useEffect } from "react";
import { useAPI } from "../../util/useAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import ResultContainer from "../search/ResultContainer";
import DownloadAllButton from "../buttons/DownloadAllButton";
import DownloadSelectedButton from "../buttons/DownloadSelectedButton";
import DownloadCSVButton from "../buttons/DownloadCSVButton";
import DownloadAssets from "../buttons/DownloadAssets";

/** CSS */
import styles from "./MyOrderView.module.scss";

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "../buttons/Button";


function MyOrderView() {
  const history = useHistory();
  const [mediaIds, setMediaIds] = useState({});
  const allIsSelected = Object.values(mediaIds).filter((selected) => !selected).length === 0
  let { orderId } = useParams();
  let { orderDate } = useParams();

  const [{ data: downloadResponse }] = useAPI(
    {
      url: "/properties/download_steps",
      method: "GET",
    },
    { manual: false }
  );
  const [download, setDownload] = useState([]);
  useEffect(() => {
    if (downloadResponse) {
      setDownload(downloadResponse.data);
    }
  }, [downloadResponse]);

  const handleSelectAll = () => {
    setMediaIds((current) => {
      const newValues = {...current}
      Object.keys(newValues).forEach((key) => newValues[key] = allIsSelected ? false : true)
      return newValues
    })
  }

  return (
    <>
      <Container>
        <div className={styles.myOrderStyles}>
          <a href="#" className={styles.goBack} onClick={() => history.go(-1)}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go back
          </a>
          <Row className={styles.myOrderDetail}>
            <Col md={8}>
              <h4>Your order  {orderId} on  {orderDate}</h4>
            </Col>
            <Col md={4}>
              <h5>How to use/download</h5>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <a href="/order" className={styles.myOrderBack}>Back to orderlist</a>
            </Col>
            <Col md={4}>
              {download.value}
            </Col>
          </Row>
          <hr />
          <Row className={"mb-2 " + styles.buttonTop}>
            <Button disabled={!mediaIds} size="small" className={styles.selectAll} onClick={handleSelectAll}>{allIsSelected ? "Deselect all" : "Select all"}</Button>
            <DownloadSelectedButton mediaIds={mediaIds} />
            <DownloadCSVButton orderId={orderId} />
          </Row>
        </div>
        <ResultContainer mode="order" orderId={orderId} orderMediaIds={mediaIds} setOrderMediaIds={setMediaIds}></ResultContainer>
      </Container>
    </>
  );
}

export default MyOrderView;
