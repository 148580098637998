import React, { useState, useEffect, useRef } from 'react'
import styles from './Footer.module.scss'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ReactComponent as ScrollIcon } from '../../assets/images/scroll.svg'
import { useLocation } from 'react-router-dom'


function Footer() {
    const [showFooter, setShowFooter] = useState(false);
    const location = useLocation();
    const scrollHandler = () => {
        window.scrollTo(0, 0);
    }

    const handleScroll = () => {
        if (window.pageYOffset > 10 && location.pathname === '/search') {
            setShowFooter(true);
        } else {
            setShowFooter(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {showFooter ? (
                <Row className={[styles.footer, "mr-0"].join(" ")}>
                    <Col md={12} className="text-right pr-0">
                        <ScrollIcon className="pointer" onClick={scrollHandler} />
                    </Col>
                </Row >
            ) : null}
        </>
    )
}

export default Footer
