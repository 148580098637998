/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import styles from "./AddToSelectionButton.module.scss";
import { UserContext } from "../../util/userContext";
import { useAPI } from "../../util/useAPI";
import BaseButton from "./BaseButton";

const defaultSelectionValues = {
  selectionCount: 0,
  selectionIds: [],
  hasProducts: false
}

const Component = (props) => {
  const { user, setUser } = useContext(UserContext);
  const [selected, setSelected] = useState(false);
  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {},
    { manual: true }
  );

  useEffect(() => {
    if (user && user?.selectionIds && user.selectionIds.includes(props.item.id)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [user.selectionIds, props.item.id]);

  const add = (id) => {
    setUser({ ...user, ...{ loading: true } });
    requestHandler({
      method: "POST",
      url: "/selections",
      data: {
        id: id,
      },
    });
  };

  const remove = (id) => {
    setUser({ ...user, ...{ loading: true } });
    requestHandler({
      url: "/selections/" + id,
      method: "DELETE",
    });
  };

  useEffect(() => {
    if (data) {
      setUser((current) => ({...current, selectionIds: data?.data?.mediaIds, selectionCount: data?.data?.count, hasProducts: data?.data?.hasProducts, loading: false}))
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!error) {
    return (
      <React.Fragment>
        {selected ? (
          <a
            id={"select_media_" + props.item.id}
            href={void 0}
            data-id={selected}
            onClick={(e) => {
              remove(props.item.id);
              e.stopPropagation();
            }}
            title="Remove from My Selected Asset"
            className={classnames(props.styles[props.classId], styles.remove, styles.iconAddToSelection, styles[props.classId])}
          ></a>
        ) : (
          <a
            id={"unselect_media_" + props.item.id}
            href={void 0}
            data-id={selected}
            onClick={(e) => {
              add(props.item.id);
              e.stopPropagation();
            }}
            title="Add to My Selected Asset"
            className={classnames(props.styles[props.classId], styles.add, styles.iconAddToSelection, styles[props.classId])}
          ></a>
        )}
      </React.Fragment>
    );
  }
};

var AddToSelectionButton = BaseButton(Component);

export default AddToSelectionButton;
