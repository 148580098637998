/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import classnames from "classnames";

import styles from "./DownloadMenuButton.module.scss";
import DownloadTemplates from "../media/DownloadTemplates";
import BaseButton from "./BaseButton";

class DownloadMenuButton extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <span
          id={"download_" + this.props.item.id}
          title="Download Options"
          className={classnames(
            this.props.styles[this.props.classId],
            styles.iconDownloadButton,
            "position-absolute",
            styles.menuHanlder
          )}
        >
          <div className={classnames(styles.downloadTemplateContainer)}>
            <DownloadTemplates {...this.props} type="menu" />
          </div>
        </span>
      </React.Fragment>
    );
  }
}

DownloadMenuButton = BaseButton(DownloadMenuButton);

export default DownloadMenuButton;
