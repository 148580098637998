import React, { useState, useEffect, useContext } from "react";
import { useAPI } from "../../util/useAPI";

/** Bootstrap components */
import styles from "./Category.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CategoryButton from "../buttons/CategoryButton";
import { FilterContext } from "../../util/filterContext";

function Category({ showSlide = true, welcome = false }) {
  const [displayImage, setDisplayImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const { filter } = useContext(FilterContext);
  const [categoryPropertyResponse, setCategoryPropertyResponse] =
    useState(null);
  const [categoryImages, setCategoryImages] = useState([]);
  const [categoryImageUrls, setCategoryImageUrls] = useState([]);
  const [displayDescription, setDisplayDescription] = useState("");

  //Configuration
  const showCategoryDescription = false;

  //Get Category Information API
  const [{ data: categoryResponse }, handleCategories] = useAPI(
    {
      url: "/categories",
      method: "GET",
    },
    { manual: true }
  );

  //Get Category Banner Media Ids API
  const [{ data: categoryImageResponse }, handleCategoryImages] = useAPI(
    {
      url: "/properties/category_headline_images",
      method: "GET",
    },
    { manual: false }
  );

  //Getting the Media Details API
  const [{ data: mediaResponse }, handleRequest] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  //Initial effect
  useEffect(() => {
    try {
      const categoryStore = window.localStorage.getItem("categories");

      if (categoryStore && JSON.parse(categoryStore).length > 0) {
        setCategories(JSON.parse(categoryStore));
      } else {
        handleCategories();
      }

      handleCategoryImages();
    } catch (error) {
      handleCategories();
      handleCategoryImages();
    }
  }, []);

  useEffect(() => {
    //category response set to state and store in local storage
    if (categoryResponse) {
      setCategories(categoryResponse.data);
      localStorage.setItem("categories", JSON.stringify(categoryResponse.data));
    }

    //Always load the category images to get new updates from the qbank
    if (
      categoryImageResponse &&
      categoryImageResponse.data &&
      categoryImageResponse.data.value
    ) {
      let categoryImageIds = [];
      for (var key in categoryImageResponse.data.value) {
        let img = JSON.parse(categoryImageResponse.data.value[key]);
        if (img && img.mediaid) {
          categoryImageIds.push(img.mediaid);
        }
      }
      setCategoryPropertyResponse(categoryImageIds);
      handleRequest({
        url:
          "/media?limit=10&mediaIds[]=" + categoryImageIds.join("&mediaIds[]="),
      });
    }
  }, [categoryResponse, categoryImageResponse]);

  useEffect(() => {
    let categoryImageUrls = [];
    if (mediaResponse && mediaResponse.data) {
      let mediaObjects = mediaResponse.data;
      let selectedMediaResponse = null;
      let index = null;
      //setting the media response according the correct order in property response
      for (var key in categoryPropertyResponse) {
        index = mediaObjects.findIndex(
          (media) => media.id === categoryPropertyResponse[key]
        );
        if (index >= 0) {
          selectedMediaResponse = mediaObjects[index];
          categoryImageUrls.push(selectedMediaResponse.remotePreviewFile);
        }
      }
    }
    setCategoryImages(categoryImageUrls);
    if (JSON.stringify(categoryImageUrls).length > 0) {
      localStorage.setItem("categoryImages", JSON.stringify(categoryImageUrls));
    }
    setCategoryImageUrls(categoryImageUrls);
  }, [mediaResponse]);

  function loadCategoryImages() {
    //Identify current category and set index of the banner of selected category
    let currentCategoryId = parseInt(filter.categoryId);
    let currentCategoryIndex =
      currentCategoryId === 0
        ? 0
        : categories.findIndex((category) => category.id === currentCategoryId);
    if (categoryImageUrls && categoryImageUrls[currentCategoryIndex]) {
      setDisplayImage(currentCategoryIndex);
    } else {
      let maxIndex = categoryImageUrls.length - 1;
      if (maxIndex >= 0) {
        setDisplayImage(maxIndex);
      }
    }

    //If category description is enabled, set description to display
    if (showCategoryDescription) {
      let categoryInfo = categories[currentCategoryIndex];
      if (categoryInfo && categoryInfo.description) {
        setDisplayDescription(categoryInfo.description);
      } else {
        setDisplayDescription("");
      }
    }
  }

  useEffect(() => {
    if (categoryImageUrls.length > 0) {
      loadCategoryImages();
    }
  }, [filter.categoryId, categoryImageUrls]);

  return (
    <>
      <Row className={"ml-0 mr-0 " + styles.categoryRow}>
        <Col
          md={12}
          className={
            welcome
              ? styles.categoryBottom + " pl-0"
              : "pl-0 " + styles.categorySpread
          }
        >
          {categories.map((item, index) => {
            return (
              <CategoryButton
                title={item.name}
                id={item.id}
                key={item.id}
                welcome={welcome}
              />
            );
          })}
        </Col>
      </Row>
      {showSlide ? (
        <Row>
          <Col md={12} className="mt-4">
            {displayImage !== null && categoryImageUrls.length > 0 ? (
              <img
                src={categoryImageUrls[displayImage]}
                className={["img-fluid"].join(" ")}
                alt="Category Banner"
              />
            ) : null}
            <div className={styles.categoryTitle}>{displayDescription}</div>
          </Col>
        </Row>
      ) : null}
    </>
  );
}

export default Category;
