import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

/** CSS */
import styles from "./DownloadCSVButton.module.scss";
import { useAPI } from "../../util/useAPI";
import { UserContext } from "../../util/userContext";
import { useParams } from "react-router-dom";

function DownloadCSVButton({ orderId, disable }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [templates, setTemplates] = useState(null);
  const [classifications, setClassifications] = useState({});
  const { user, setUser } = useContext(UserContext);
  const { hash } = useParams();

  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  const downloadAllButtonClickHandler = () => {
    setUser({ ...user, loading: true });
    let url = "/selections/downloadOptions";
    if (hash) {
      url = "/selections/downloadOptions?hash=" + hash;
    }
    requestHandler({
      url: url,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.target.submit();
    handleClose();
  };

  useEffect(() => {
    if (data) {
      setTemplates(data.data);
      setClassifications(data.classifications);
    }
  }, [data]);

  useEffect(() => {
    if (templates) {
      setUser({ ...user, loading: false });
      handleShow();
    }
  }, [templates]);

  return (
    <>
      <a href={!disable ? "/api/v1/orders/downloadProductInformation/" + orderId : "#"}
        id="downloadCSVButton"
        className={[styles.buttonStyle, disable ? styles.disabled : null].join(" ")}>
        PRODUCT INFORMATION CSV
      </a>
    </>
  );
}

export default DownloadCSVButton;
