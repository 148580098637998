import React, { useContext, useState, useEffect } from "react";
import { useAPI } from "../../util/useAPI";
import DownloadAllButton from "../buttons/DownloadAllButton";
import ShareListButton from "../buttons/ShareListButton";
import RemoveAllSelection from "./RemoveAllSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../util/userContext";
import DownloadAssets from "../buttons/DownloadAssets";

/** CSS */
import styles from "./MySelectionListControls.module.scss";

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Img from "react-bootstrap/Image";
import DownloadSelectionProductInfo from "../buttons/DownloadSelectionProductInfo";


function MySelectionListControlles({ fileName, setFileName }) {
  const { user, setUser } = useContext(UserContext);
  const [count, setCount] = useState(0);
  const [hasProducts, setHasProducts] = useState(false);
  const history = useHistory();
  
  const goBack = () => {
    let prevPath = localStorage.getItem("prevPathName");
    let searchParam = localStorage.getItem("searchParam");
    if(prevPath && searchParam){
      history.push(JSON.parse(prevPath)+''+ JSON.parse(searchParam)); 
    }else{
      history.push('/search');
    }
  }
  
  const [{ data: selectionMediaResponse }] = useAPI(
    {
      url: "/properties/my_selection_image",
      method: "GET",
    },
    { manual: false }
  );

  useEffect(() => {
    if (user && user.selectionCount !== undefined) {
      setCount(user.selectionCount);
    } else {
      setCount(0);
    }
    if (user && user.hasProducts !== undefined) {
      setHasProducts(user.hasProducts);
    } else {
      setHasProducts(false);
    }
  }, [user]);

  //Search specific media ids (Use in selection main view from the URL)
  const [{ data: detailMedia }, detailMediaRequestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  useEffect(() => {
    if (selectionMediaResponse && selectionMediaResponse.data) {
      try{
        let queryString = JSON.parse(selectionMediaResponse.data.value);
        detailMediaRequestHandler({
          url: "/media/" + queryString.mediaid,
        });
      }catch(e){
        //no banner
      }      
    }
  }, [selectionMediaResponse]);

  //Load Detail View from the URL
  const [selection, setSelection] = useState([]);
  useEffect(() => {
    if (detailMedia) {
      setSelection(detailMedia.data.remotePreviewFile);
    }
  }, [detailMedia]);


  return (
    <>
      <Container>
        <div className={styles.mySelectionListControllesStyels}>
          <a href="#" className={styles.goBack} onClick={goBack}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go back
          </a>
          {count > 0 ?

          <Row className={"mb-2 " + styles.buttonTop}>
            <DownloadAllButton fileName={fileName} />
            <RemoveAllSelection />
            <ShareListButton />
            {hasProducts ? <DownloadSelectionProductInfo /> : null}
            <DownloadAssets fileName={setFileName} />
          </Row>
          : ""}
          <Row>
            {selection !== null && selection.length > 0 ? (
              <Img src={selection} className={styles.selectionImage} alt="My Selected Assets" ></Img>
            ) : null}

          </Row>
        </div>
      </Container>
    </>
  );
}

export default MySelectionListControlles;
