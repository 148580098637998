import React, { useContext } from "react";
import { useAPI } from "../../util/useAPI";

import ViewModeButton from "../buttons/ViewModeButton";
import SearchStat from "./SearchStat";
import SortButton from "../buttons/SortButton";
import classnames from "classnames";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/** CSS */
import styles from "./SearchControls.module.scss";
import Button from "../buttons/Button";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../util/userContext";

const sortItems = [
  { name: "Latest", onClick: () => {} },
  { name: "Popular", onClick: () => {} },
];

const maxSelectAllAmount = 600;

function SearchControls({ gridMode, setGridMode, mode, totalRecords }) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  searchParams.set("limit", maxSelectAllAmount);

  const { user, setUser } = useContext(UserContext);
  const [{ data, loading, error, response }, setSelectionRequestHandler] =
    useAPI(
      {
        url: `/selections/addSearchToSelection?${searchParams.toString()}`,
        method: "POST",
      },
      { manual: true }
    );

  const selectAllHandler = async () => {
    setUser({ ...user, ...{ loading: true } });
    try {
      const { data } = await setSelectionRequestHandler();
      setUser((current) => ({
        ...current,
        selectionIds: data?.data?.mediaIds,
        selectionCount: data?.data?.count,
        loading: false,
      }));
    } catch (e) {
      console.log(e);
      setUser((current) => ({
        ...current,
        loading: false,
      }));
    }
  };

  return (
    <div className={styles.toolBarIntro}>
      <Container>
        <Row>
          <Col md={12} className={styles.toolbarContainer}>
            <Row>
              <div className="col-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start order-2 order-md-1 mt-3 mt-md-0">
                <SearchStat totalRecords={totalRecords} />
              </div>
              <div
                className={classnames(
                  styles.toolBarWrapper,
                  "col-12 col-md-7 text-right d-flex align-items-center justify-content-center justify-content-md-end order-1"
                )}
              >
                {mode !== "selection" ? (
                  <>
                    <Button
                      size="small"
                      className="mr-3"
                      onClick={selectAllHandler}
                      disabled={totalRecords > maxSelectAllAmount}
                      title={
                        totalRecords > maxSelectAllAmount
                          ? `Maximum of ${maxSelectAllAmount} media can be selected at once`
                          : ""
                      }
                    >
                      Select all
                    </Button>
                    <span
                      className={classnames(styles.verticalSeparator, "mr-3")}
                    />
                  </>
                ) : null}
                {mode !== "selection" ? (
                  <div
                    className={classnames(
                      styles.alignRight,
                      "mt-0 mr-3",
                      "sortOptions"
                    )}
                  >
                    <SortButton sort={sortItems} />
                  </div>
                ) : (
                  ""
                )}
                <span
                  className={classnames(styles.verticalSeparator, "mr-1")}
                />
                <div
                  className={classnames(
                    styles.alignRight,
                    "d-none",
                    "d-sm-block"
                  )}
                >
                  <ViewModeButton
                    gridMode={gridMode}
                    setGridMode={setGridMode}
                    className="d-md-none d-lg-block"
                  />
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SearchControls;
