import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

/** CSS */
import styles from "./DownloadSelectedButton.module.scss";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useAPI } from "../../util/useAPI";
import { UserContext } from "../../util/userContext";
import { useParams } from "react-router-dom";

function DownloadSelectedButton({ mediaIds}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [templates, setTemplates] = useState(null);
  const [classifications, setClassifications] = useState({});
  const { user, setUser } = useContext(UserContext);
  const { hash } = useParams();
  const [query, setQuery] = useState('');
  const fileName = 'image'

  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  const downloadSelectedButtonClickHandler = () => {
    if (!mediaIds) {
      return
    }
    const selectedMediaIds = Object.keys(mediaIds).filter((key) => mediaIds[key])
    let queryList = "mediaIds[]="+selectedMediaIds.join('&mediaIds[]=');
    setQuery(queryList);
    setUser({ ...user, loading: true });
    
    requestHandler({
      url: "/orders/downloadOptions?"+queryList,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.target.submit();
    handleClose();
  };


  useEffect(() => {
    if (data) {
      setTemplates(data.data);
      setClassifications(data.classifications);
    }
  }, [data]);

  useEffect(() => {
    if (templates) {
      setUser({ ...user, loading: false });
      handleShow();
    }
  }, [templates]);

  return (
    <>
      <Button
        id="downloadSelectedButton"
        className={styles.buttonStyle}
        disabled={mediaIds && Object.values(mediaIds).filter((selected) => selected).length === 0}
        onClick={() => {
          downloadSelectedButtonClickHandler();
        }}
      >
        Download SELECTED
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form
          onSubmit={handleSubmit}
          method="post"
          target="_blank"
          action={process.env.REACT_APP_MEDIAPORTAL_API_URL + "/orders/archiveDownload?fileName="+fileName + "&" + query}
        >
          <Modal.Header closeButton>
            <Modal.Title id="share-link" className={styles.modalTitle}>
              DOWNLOAD SELECTED
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={styles.bodyText}>
              Make sure to choose image size for any selected images and video
              size for any selected videos.
            </p>
            <Row className={styles.bodyAlign}>
              {templates
                ? templates.map((item) => {
                    return (
                      <Col
                        className={classnames("col", "col-sm-12", "col-md-4")}
                      >
                        <div className={styles.cattitle}>{item.title}</div>
                        <FormGroup>
                          {item.templates.map((template, index) => {
                            return (
                              <>
                                <label className="inputCustomCheckbox form-check">
                                  <input id={item.classification + "_" + index} name={item.classification + "[]"} 
                                  value={template.templateId} type="checkbox" className={styles.check} 
                                  disabled={ !classifications[item.classification] } />
                                  <span className="selectedMark"></span>
                                  <label for={item.classification + "_" + index} className="form-check-label">{template.name}</label>
                                </label>
                              </>
                            );
                          })}
                        </FormGroup>
                      </Col>
                    );
                  })
                : ""}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="secondary">
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Download
            </Button>
            {hash ? <input type="hidden" value={hash} name="hash" /> : ""}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default DownloadSelectedButton;
