import React, { useState, useContext, useEffect } from "react";
import { FilterContext } from "../../util/filterContext";

/** CSS */
import styles from "./SearchStat.module.scss";

/** Bootstrap components */
import { UserContext } from "../../util/userContext";

function SearchStat(props) {
  const [keyword, setKeyword] = useState(null);
  const { user } = useContext(UserContext);
  const { filter } = useContext(FilterContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(user.resultLoading);
    }
  }, [user]);

  useEffect(() => {
    setKeyword("");
  }, []);

  useEffect(() => {
    if (filter && filter.keyword) {
      setKeyword(filter.keyword);
    } else {
      setKeyword("");
    }
  }, [filter]);

  if (loading) {
    return (
      <>
        <span>Loading...</span>
      </>
    );
  } else {
    return (
      <>
        <span id="searchStat" className={styles.searchStatFont}>
          {" " + props.totalRecords + " matching results"}{" "}
          {keyword ? "- keyword " + keyword : null}
        </span>
      </>
    );
  }
}

export default SearchStat;
