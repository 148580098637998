import React, { useContext, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { FilterContext } from "../../util/filterContext";
import { ResultContext } from "../../util/resultContext";
import searchHandler from "../../util/searchHandler";
import { useHistory } from "react-router-dom";

/** CSS */
import styles from "./CategoryButton.module.scss";

function CategoryButton(props) {
  const [currentCategoryId, setCurrentCategoryId] = useState(1);
  const [active, setActive] = useState(false);
  const { filter, setFilter } = useContext(FilterContext);
  const { result, setResult } = useContext(ResultContext);
  const history = useHistory();

  const handleCategoryClick = (e) => {
    e.preventDefault();
    let categoryId = e.target.getAttribute("data-id");

    if (filter.categoryId !== categoryId) {
      setCurrentCategoryId(categoryId);
      let filters = {
        ...filter,
        ...{
          categoryId: currentCategoryId,
          keyword: "",
          propertyFilters: filter,
        },
      };
      setFilter(filters);
      history.push("/search" + searchHandler(filters));
    }
  };

  useEffect(() => {
    setCurrentCategoryId(props.id);
    setResult({
      ...result,
      ...{ counts: {} }, //set filter counts to null when category change
    });
    if (filter && parseInt(currentCategoryId) === parseInt(filter.categoryId)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [filter, currentCategoryId]);

  return (
    <>
      <Button
        id={"category_" + props.id}
        onClick={(e) => {
          handleCategoryClick(e);
        }}
        data-id={props.id}
        className={props.welcome ? "p-2 pl-4 pr-4 mr-3 mt-3 " + styles.buttonStyle
          : styles.buttonMargin + " p-2 pl-4 pr-4 mr-3 mt-3 " +styles.buttonStyle
        }
      >
        {props.title}
      </Button>
    </>
  );
}

export default CategoryButton;
