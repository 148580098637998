import { configure, makeUseAxios } from "axios-hooks";
import LRU from "lru-cache";
import axios from "axios";
import * as AxiosLogger from "axios-logger";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_MEDIAPORTAL_API_URL,
});

Axios.defaults.withCredentials = true;

const cache = new LRU({ max: 20 });
configure({ axios, cache });

if (process.env.NODE_ENV === "development") {
  Axios.interceptors.request.use(AxiosLogger.requestLogger);
  Axios.interceptors.response.use(AxiosLogger.responseLogger);
}

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let location = window.location.href;
    let loginRoute = "/login";
    if (
      error.response &&
      error.response.status === 401 &&
      !location.includes(loginRoute)
    ) {
      localStorage.removeItem("user");
      window.location.href = loginRoute;
    }
    return Promise.reject(error);
  }
);

export const useAPI = makeUseAxios({
  axios: Axios,
});
