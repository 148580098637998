import React, { useContext, useState, useEffect } from 'react'
import { useAPI } from "./useAPI";
import qs from "qs";
import { useLocation } from "react-router-dom";

function useResult() {

    const location = useLocation();
    const [show, setShow] = useState(false);
    const [expandedItem, setExpandedItem] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);


    //Detail View Modal Handlers
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);

    //Search specific media ids (Use in detail view from the URL)
    const [{ data: detailMedia }, detailMediaRequestHandler] = useAPI(
        {
            method: "GET",
        },
        { manual: true }
    );

    //Load Detail View from direct url navigation
    useEffect(() => {
        let queryString = qs.parse(location.search);
        if (queryString && queryString.mediaId) {
            detailMediaRequestHandler({
                url: "/media?mediaIds[]=" + queryString.mediaId,
            });
        }
    }, []);

    //Set the expanded view when detail info selected
    useEffect(() => {
        let queryString = qs.parse(location.search);
        if (detailMedia && detailMedia.data && detailMedia.data.length > 0) {
            let currentIndex = 0;
            let item = null;
            detailMedia.data.map((ele, index) => {
                if (ele.id == queryString.mediaId) {
                    currentIndex = index;
                    item = ele;
                    return;
                }
            });
            setExpandedItem(item);
            setCurrentIndex(currentIndex);
        }
    }, [detailMedia]);


    return { handleClose, handleShow, show, setExpandedItem, expandedItem, setCurrentIndex, currentIndex }
}

export default useResult
