import React from 'react';

import styles from "./BaseButton.module.scss";

const BaseButton = (CustomizedButton) => {

    class Button extends React.Component {

        render() {
            return <CustomizedButton styles={styles} {...this.props} />;
        }

    }

    return Button;
};

export default BaseButton;


