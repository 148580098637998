import React, { useContext, useEffect, useState } from "react";

/** CSS */
import styles from "./DownloadAssets.module.scss";
import Select from 'react-dropdown-select';

function DownloadAssets(props) {
  const options = [
    { value: 'image', label: 'Image Name' },
    { value: 'file', label: 'File Name' },
    { value: 'type', label: 'EAN - Image Type' }
  ]
  const handleChange = (e) => {
    props.fileName(e[0]['value']);
  };

  return (
    <>
    <Select 
      options={options} 
      placeholder="Image Name"
      onChange={(values) => handleChange(values)}
      className={["dowload-asset", "react-dropdown-select-dropdown-handle", styles.dropdown].join(" ")}
      />
    </>
  );
}

export default DownloadAssets;
