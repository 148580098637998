import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "react-bootstrap/Image";

/** Image assets */
import Logo from "../../assets/images/logo.svg";

/** CSS */
import styles from "./ShareSelectionHeader.module.scss";

function ShareSelectionHeader() {
  return (
    <header>
      <Container>
        <Row>
          <Col md={3}>
            <Link to="/">
              <Img src={Logo} className={styles.logo} alt="Jlindeberg Logo" />
            </Link>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default ShareSelectionHeader;
