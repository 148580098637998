/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { FilterContext } from "../../util/filterContext";

import styles from "./SortButton.module.scss";

function SortButton() {
  const { filter, setFilter } = useContext(FilterContext);
  const [currentSort, setCurrentSort] = useState(null);
  const [sortItems, setSortItems] = useState([]);

  const showSeperator = false;

  const handleSort = (e) => {
    e.preventDefault();
    let sort = e.target.getAttribute("mode");
    setFilter({ ...filter, ...{ sort: sort } });
  };

  useEffect(() => {
    if (filter) {
      if (filter.keyword) {
        setSortItems([
          { name: "Relevance", sort: "relevance" },
          { name: "Popular", sort: "popularity" },
          { name: "Latest", sort: "id" },
        ]);
      } else {
        setSortItems([
          { name: "Popular", sort: "popularity" },
          { name: "Latest", sort: "id" },
        ]);
      }

      if (filter.sort) {
        setCurrentSort(filter.sort);
      }
    }
  }, [filter]);

  return (
    <>
      {sortItems.map((sortOption, index) => (
        <span key={"sort_" + sortOption.sort}>
          <a
            id={"sort_" + sortOption.sort}
            href="#"
            mode={sortOption.sort}
            onClick={(e) => handleSort(e)}
            className={
              currentSort === sortOption.sort
                ? styles.activeSort
                : styles.defaultSort
            }
          >
            {sortOption.name}
          </a>
          {index < sortItems.length - 1 && showSeperator ? " | " : " "}
        </span>
      ))}
    </>
  );
}

export default SortButton;
