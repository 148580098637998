import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../util/userContext";
import { MessageContext } from "../../util/messageContext";

/** Bootstrap components */
import Img from "react-bootstrap/Image";

/** CSS */
import styles from "./LogoutButton.module.scss";

/** Image */
import logoutIcon from "../../assets/images/logout.svg";

function LogoutButton(props) {
  const { user, setUser } = useContext(UserContext);
  const { setMessage } = useContext(MessageContext);

  const doLogoutHandler = (e) => {
    console.log("Running doLogoutHandler");
    fetch("/api/v1/auth/logout").then((r) => {
      console.log("Response recieved?");
      e.preventDefault();
      localStorage.clear();
      setUser(null);
      setMessage({});
    });
  };

  return (
    <>
      <span className={"text-nowrap " + styles.loginUser}>
        {user.displayName}
        <Link
          id="logoutButton"
          {...props}
          onClick={(e) => {
            doLogoutHandler(e);
          }}
          to="/"
        >
          <Img className={styles.logoutIconStyle} src={logoutIcon} />
        </Link>
      </span>
    </>
  );
}

export default LogoutButton;
