import React, { useContext, useState, useEffect } from 'react';

import Logo from "../assets/images/logo.png";
import Toast from "react-bootstrap/Toast";
import Modal from "react-bootstrap/Modal";
import { MessageContext } from '../util/messageContext';
import Background from '../components/common/Background';
import styles from './Initial.module.scss';
import BottomLogo from '../assets/images/bottom-logo.png';
import { useAPI } from '../util/useAPI';

function DefaultLayout({ children }) {
    const { message, setMessage } = useContext(MessageContext);
    const [showToast, setShowToast] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [childProps, setChildProps] = useState({});
    const [background, setBackground] = useState({})

    const [{ data: TocResponse }] = useAPI(
        {
            url: "/properties/toc_file",
            method: "GET",
        },
        { manual: false }
    );

    const [{ data: masterDataResponse }] = useAPI(
        {
            url: "/auth/masterData",
            method: "GET",
        },
        { manual: false }
    );

    const [{ data: detailMedia }, detailMediaRequestHandler] = useAPI(
        {
            method: "GET",
        },
        { manual: true }
    );

    const [tocFile, setTocFile] = useState([]);
    useEffect(() => {
        if (TocResponse && TocResponse.data) {
            let queryString = JSON.parse(TocResponse.data.value);
            detailMediaRequestHandler({
                url: "/media/" + queryString.mediaid + '/null',
            });
        }
    }, [TocResponse]);

    useEffect(() => {
        if (detailMedia) {
            setTocFile(detailMedia.data);
        }
    }, [detailMedia]);

    useEffect(() => {
        if (
            masterDataResponse &&
            masterDataResponse.data
        ) {
            // console.log("DATA", masterDataResponse.data.backgroundMedia)
            // setLoginText(loginHeaderResponse.data.value);
            // setTocBody(tocResponse.data.value);
            setBackground({ mediaUrl: masterDataResponse.data.backgroundMedia, mediaType: masterDataResponse.data.backgroundType })
            setChildProps({ loginHeader: masterDataResponse.data.loginText, tocBody: masterDataResponse.data.toc })
        }

    }, [masterDataResponse]);

    useEffect(() => {
        if (message.type === 'toast') {
            setShowToast(true);
            setTimeout(() => { setMessage({ title: '', message: '', type: '' }); setShowToast(false); }, 6000);
        }
        if (message.type === 'modal') {
            setShowModal(true);
        }
    }, [message])

    const closeModelHandler = () => {
        setShowModal(false);
    }


    return (
        <>
            <Background styles={styles} background={background} />
            <div className="backdrop">
                <div className="heading">
                </div>
                <div className="clearfix"></div>
                {React.cloneElement(children, { ...childProps })}
                <div className="clearfix"></div>
                <div className={styles.footer}>
                    
                </div>
            </div>

            <Toast className={styles.toast} show={showToast}>
                {/* <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">{message.title}</strong>
                </Toast.Header> */}
                <Toast.Body>{message.message}</Toast.Body>
            </Toast>

            <Modal show={showModal} onHide={closeModelHandler} animation={false}>
                <Modal.Body> <iframe className={styles.tocIframe} src={tocFile.multimediaResource} /></Modal.Body>
            </Modal>

        </>
    )
}

export default DefaultLayout
