import React, { useState, useContext, useEffect } from "react";

import { FilterContext } from "../../util/filterContext";
import { ResultContext } from "../../util/resultContext";
import searchHandler from "../../util/searchHandler";
import { useHistory } from "react-router-dom";
import { useAPI } from "../../util/useAPI";
import Search from "../../assets/images/search.svg";
import { CSSTransition, TransitionGroup } from "react-transition-group";

/** Bootstrap components */
import Img from "react-bootstrap/Image";

/** CSS */
import styles from "./KeywordSearchAnimated.module.scss";

const classes = {
  searchTransitionEnter: {
    width: "0px",
  },

  searchTransitionEnterActive: {
    width: "295px",
    transition: "all 400ms",
  },

  searchTransitionExit: {
    width: "295px",
  },

  searchTransitionExitActive: {
    width: "0px",
    transition: "all 400ms",
  },
};

function KeywordSearchAnimated({ expandSearch = false }) {
  const { filter, setFilter } = useContext(FilterContext);
  const [keyword, setKeyword] = useState("");
  const { result, setResult } = useContext(ResultContext);
  const history = useHistory();
  const [visible, setVisible] = useState(expandSearch);

  //Set keyword to filter to perform search and invoke freetext filter api to get media ids
  const handleKeywordSearch = (keyword) => {
    setFilter({
      ...filter,
      ...{
        keyword: keyword,
      },
    });
    keywordFilterHandler({ url: "/filters/freetext?query=" + keyword });
    history.push(
      "/search" +
        searchHandler({
          ...filter,
          ...{
            keyword: keyword,
          },
        })
    );
  };

  //Load keyword matching media ids from API
  const [{ data: keywordFilterResponse }, keywordFilterHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  //listen to resetKeyword to set the keyword to empty
  useEffect(() => {
    if (result && result.resetKeyword === true) {
      setKeyword("");
      setResult({
        ...result,
        ...{ resetKeyword: false, freetextFilterMediaIds: false },
      });
    }
  }, [result, setResult]);

  //set the result context with the free text search media ids to use in filter counts
  useEffect(() => {
    if (keywordFilterResponse && keywordFilterResponse.data) {
      if (keywordFilterResponse.data.mediaIds) {
        setResult({
          ...result,
          ...{ freetextFilterMediaIds: keywordFilterResponse.data.mediaIds },
        });
      }
    }
  }, [keywordFilterResponse]);

  return (
    <div className={styles.searchWrapper}>
      <CSSTransition
        in={visible}
        timeout={1200}
        classNames="float-left"
        unmountOnExit
        classNames="search"
      >
        <input
          id="keywordSearch"
          type="text"
          placeholder="Search"
          className={styles.keywordInput}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onKeyPress={(event) =>
            event.key === "Enter" && handleKeywordSearch(event.target.value)
          }
          value={keyword}
        />
      </CSSTransition>

      <button
        id="searchButton"
        className={styles.button}
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <Img src={Search} />
      </button>
    </div>
  );
}

export default KeywordSearchAnimated;
