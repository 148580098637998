import React, { useState, useEffect, useContext } from "react";
import { useAPI } from "../../util/useAPI";
import { Link } from "react-router-dom";
import { MessageContext } from "../../util/messageContext";

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { ReactComponent as LeftArrow } from '../../assets/images/leftArrow.svg';
import styles from "./Login.module.scss";

function ForgotPassword() {
  const { setMessage } = useContext(MessageContext);
  const [username, setUsername] = useState("");
  const [{ data, loading, error }, forgotPasswordHandler] = useAPI(
    {
      url: `/auth/forgot-password/${username}`,
      method: "GET",
    },
    { manual: true }
  );

  useEffect(() => {
    console.log(data);
    if (data && data.data) {
      setMessage({ title: 'Successfully sent reset password email', message: "Password reset email was successfully sent.", type: 'toast' })
    } else if (error) {
      setMessage({ title: 'Unable to send a reset password email', message: "Failed to send a password reset email.", type: 'toast' })
    }
  }, [data, error, setMessage]);

  return (
    <Container>
      <Row className={"justify-content-md-center " + styles.frame}>
        <Col md={12} className={styles.noPadding}>
          <Form>
            <h2 className={styles.title}>Reset password</h2>
            <Form.Group>
              <Col md={3} className={styles.noPadding + " float-left"}>
                <Form.Label>USERNAME</Form.Label>
              </Col>
              <Col md={9} className={styles.noPadding + " float-left"}>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={username}
                  autoComplete="off"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group>
              <Button
                disabled={loading}
                className={styles.loginButton + " col-md-12"}
                onClick={forgotPasswordHandler}
              >
                {loading ? "Submitting..." : "Reset password"}
              </Button>
              <div className={styles.forgotPasswordText}>
                <Link to="/login" className={styles.backButton}><LeftArrow className={styles.backArrow} />Back to log in screen</Link>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
