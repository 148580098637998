import React, { useContext, useState, useEffect } from "react";
import { useAPI } from "./useAPI";
import qs from "qs";
import useInfiniteScroll from "./useInfiniteScroll";
import { useParams } from "react-router-dom";

/** Contexts */
import { UserContext } from "./userContext";

function useResult({ mode, intercept, orderId }) {
  const { user, setUser } = useContext(UserContext);

  const [templates, setTemplates] = useState(null);
  const [mobile, setMobile] = useState(false);
  const [mediaItems, setMediaItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isFetching, setIsFetching] = useInfiniteScroll(
    fetchMoreMediaItems,
    intercept
  );
  const [isNoResults, setIsNoResults] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const { hash } = useParams();
  const [previewContainer, setPreviewContainer] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);

  //Media Search API
  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  //Templates API
  const [
    {
      data: getTemplates,
      loading: getTemplateLoading,
      error: getTemplateError,
      response: getTemplateResponse,
    },
    templateRequestHandler,
  ] = useAPI(
    {
      url: "/media/templates",
      method: "GET",
    },
    { manual: true }
  );

  //Update result loading state to the context
  useEffect(() => {
    setUser({
      ...user,
      ...{ resultLoading: getTemplateLoading, totalRecords: 0 },
    });
  }, [getTemplateLoading]);

  //Initial effcts
  useEffect(() => {
    let localStorageTemplates = localStorage.getItem("templates");
    let localStorageTemplatesObj = null;

    if (localStorageTemplates) {
      localStorageTemplatesObj = JSON.parse(localStorageTemplates);
    }

    if (localStorageTemplatesObj) {
      setTemplates(localStorageTemplatesObj);
    }

    if (!templates && !localStorageTemplatesObj) {
      templateRequestHandler({});
    }
    if (window.innerWidth > 767) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  }, []);

  //Setting Templates to the local storage
  useEffect(() => {
    if (getTemplates) {
      setTemplates(getTemplates.data);
      localStorage.setItem("templates", JSON.stringify(getTemplates.data));
    }
  }, [getTemplates]);

  //Media Search Results
  useEffect(() => {
    if (data) {
      setMediaItems([...mediaItems, ...data.data]);
      setOffset(data.meta.offset + data.meta.limit);
      setIsFetching(false);
      setTotalRecords(data.meta.totalRecords);
    }

    if (error) {
      setMediaItems([...mediaItems, ...[]]);
    }

    if (response && response.status === 204) {
      setIsFetching(false);
      setIsNoResults(true && !mediaItems?.length);
      if (mediaItems.length > 0) {
        setUser({
          ...user,
          ...{ resultLoading: false },
        });
      } else {
        setUser({ ...user, ...{ resultLoading: false, totalRecords: 0 } });
        setTotalRecords(0);
      }
    }
  }, [data, error, response]);

  useEffect(() => {
    if (filterQuery && templates) {
      setOffset(0);
      setMediaItems([]);
      setUser({ ...user, ...{ totalRecords: 0 } });
      setIsNoResults(false);
      loadMediaItems(
        filterQuery,
        hash,
        offset,
        setPreviewContainer,
        mode,
        orderId,
        0
      );
    }
  }, [filterQuery, templates]);

  function loadMediaItems(
    filterQuery,
    hash,
    offset,
    previewHandler,
    mode = "search",
    orderId = null,
    forceSettedOffset = null
  ) {
    let params = {};
    let mediaOffset = forceSettedOffset !== null ? forceSettedOffset : offset;

    if (mode === "search") {
      params = {
        url: "/media" + filterQuery + "&offset=" + mediaOffset,
      };
    } else if (mode === "order") {
      params = {
        url: "/orders/media/" + orderId + "?offset=" + mediaOffset,
      };
    } else if (mode === "selection" && !hash) {
      params = {
        url: "/selections" + filterQuery + "&offset=" + mediaOffset,
      };
    } else if (mode === "selection" && hash) {
      params = {
        url:
          "/selections" +
          filterQuery +
          "&offset=" +
          mediaOffset +
          "&hash=" +
          hash,
      };
    }

    if (typeof requestHandler === "function") {
      requestHandler(params);
    }
  }

  function fetchMoreMediaItems() {
    if (!isNoResults && templates && !loading) {
      loadMediaItems(
        filterQuery,
        hash,
        offset,
        setPreviewContainer,
        mode,
        orderId
      );
    }
  }

  return {
    setTotalRecords,
    templates,
    mobile,
    loading,
    getTemplateLoading,
    setMediaItems,
    mediaItems,
    setOffset,
    offset,
    setUser,
    user,
    setIsNoResults,
    isNoResults,
    setFilterQuery,
    setPreviewContainer,
    previewContainer,
    totalRecords,
  };
}

export default useResult;
