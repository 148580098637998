import React, { useContext, useState, useEffect } from "react";
import { useAPI } from "../../util/useAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Loader from "../../assets/images/preloader.svg";
import classnames from "classnames";


/** CSS */
import styles from "./MyOrder.module.scss";

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import useInfiniteScroll from "../../util/useInfiniteScroll";
import { useCallback } from "react";
import { useInView } from "react-intersection-observer";

function MyOrder() {
  const history = useHistory();
  const [allOrdersLoaded, setAllOrdersLoaded] = useState(false)
    //Load Order list from the URL
    const [orders, setOrders] = useState([]);
    const [orderListError, setOrderListError] = useState("");
  const [isFetching, setIsFetching] = useState(false)

  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '200px',
    skip: allOrdersLoaded
  })

  const [{ data: orderMediaResponse }] = useAPI(
    {
      url: "/properties/my_order_image",
      method: "GET",
    },
    { manual: false }
  );

  //Search specific media ids (Use in my order main view from the URL)
  const [{ data: detailMedia }, detailMediaRequestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  const [{ data: ordersResponse }, fetchOrders] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );


  const loadOrders = useCallback(async () => {
    console.log("running loadOrders", allOrdersLoaded, isFetching);
    if (allOrdersLoaded || isFetching) return;
    const params = {
      url: `/orders?offset=${orders.length ? orders.length : 0}`,
    }
    if (typeof fetchOrders === 'function') {
      setIsFetching(true)
      await fetchOrders(params)
      setIsFetching(false)
    }
  }, [allOrdersLoaded, fetchOrders, isFetching, orders.length])

  useInfiniteScroll(loadOrders, inView);

  useEffect(() => {
    if (orderMediaResponse && orderMediaResponse.data) {
      let queryString = JSON.parse(orderMediaResponse.data.value);
      detailMediaRequestHandler({
        url: "/media/" + queryString.mediaid,
      });
    }
  }, [orderMediaResponse]);

  useEffect(() => {
    if (ordersResponse) {
      const json = JSON.parse(ordersResponse.data);
      if (json["error"]) {
        setAllOrdersLoaded(true);
        setOrderListError(json["error"]);
      } else {
        if (!json.length || json.length < 50) {
          setAllOrdersLoaded(true);
        }
        setOrders((current) => [...current, ...json]);
      }
    }
  }, [ordersResponse]);

  //Load Detail View from the URL
  const [order, setOrder] = useState([]);
  useEffect(() => {
    if (detailMedia) {
      setOrder(detailMedia.data.remotePreviewFile);
    }
  }, [detailMedia]);

  return (
    <>
      <Container>
        <div className={styles.myOrderStyles}>
          <a href="#" className={styles.goBack} onClick={() => history.go(-1)}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go back
          </a>
          <Row>
            {order !== null && order.length > 0 ? (
              <Img
                src={order}
                className={styles.orderImage}
                alt="My Orders"
              ></Img>
            ) : null}
          </Row>
          <Row>
            <Col md={8}>
              <Table className={styles.myOrderTable}>
                <thead className={styles.tableHeading}>
                  <tr>
                    <th>ORDER NUMBER</th>
                    <th>PURCHASED DATE</th>
                    <th>EXTERNAL DOCUMENT NUMBER</th>
                  </tr>
                </thead>
                <tbody>
                  {orderListError ? (
                    <tr>{orderListError}</tr>
                  ) : (
                    orders.map((item, index) => {
                      return (
                        <tr key={item.number} className={styles.tableRow}>
                          <td>
                            <a href={"order/" + item.number + "/" + item.date}>
                              {item.number}
                            </a>
                          </td>
                          <td>{item.date}</td>
                          <td>{item.external_document_number}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
                {isFetching ? (
                  <Img
                  src={Loader}
                  width="60"
                  className="mt-4"
                  alt="Preloader"
                />
                ) : ""}
              {allOrdersLoaded ? (
                    <div className="px-3"><i>All orders loaded</i></div>
                ) : ""}
              <div ref={ref} className={classnames(styles.hiddenRef)} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default MyOrder;
