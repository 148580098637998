import React from "react";
import { UserContext } from "./userContext";
import { FilterContext } from "./filterContext";
import { ResultContext } from "./resultContext";
import { MessageContext } from "./messageContext";

function AppContext({ children, user, filter, result, message }) {
  return (
    <MessageContext.Provider value={message}>
      <UserContext.Provider value={user}>
        <FilterContext.Provider value={filter}>
          <ResultContext.Provider value={result}>
            {children}
          </ResultContext.Provider>
        </FilterContext.Provider>
      </UserContext.Provider>
    </MessageContext.Provider>
  );
}

export default AppContext;
