import React, { useState, useEffect } from "react";
import { useAPI } from "../../util/useAPI";
import qs from "qs";
import { useLocation, Link } from "react-router-dom";

/** Bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

/** Image assets */
import Logo from "../../assets/images/logo.svg";

function ChangePassword(props) {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [key, setKey] = useState(false);
  const [created, setCreated] = useState(0);
  const [name, setName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [username, setUsername] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [{ data, loading, error }, passwordChangeHandler] = useAPI(
    {
      url: "/auth/changePassword",
      method: "POST",
    },
    { manual: true }
  );

  const updatePasswordHandler = () => {
    let valid = true;
    if (newPassword !== confirmNewPassword) {
      valid = false;
      setErrorMessage("Password Doesn't Match");
    }

    if (newPassword.length < 8) {
      valid = false;
      setErrorMessage("New Password should contain at least 8 Characters");
    }

    if (valid) {
      setErrorMessage(null);
      passwordChangeHandler({
        data: {
          username: username,
          key: key,
          created: created,
          name: name,
          newPassword: newPassword,
          verifyOnly: false,
        },
      });
    }
  };

  useEffect(() => {
    if (data && data.data) {
      setShowSuccess(true);
      setErrorMessage(null);
    } else {
      setShowSuccess(false);
    }
    if (error) {
      setErrorMessage("Invalid URL or expired!");
    }
  }, [data, loading, error]);

  useEffect(() => {
    let queryParams = qs.parse(location.search);
    if (props && props.match && props.match.params && props.match.params.key) {
      setKey(props.match.params.key);
    }

    if (queryParams) {
      if (queryParams.username) {
        setUsername(queryParams.username);
      }
      if (queryParams.created) {
        setCreated(queryParams.created);
      }
      if (queryParams.name) {
        setName(queryParams.name);
      }
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12} className="text-center mt-3 mb-2">
          <Img src={Logo} alt="Logo" />
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-5">
        <Col md={6}>
          <Form>
            <h2 className="text-center">New Password</h2>
            <p>Enter your New password</p>
            <div>
              {errorMessage && !loading && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {loading && (
                <div className="alert alert-info">Submitting ....</div>
              )}
              {showSuccess && !loading && !error && (
                <div className="alert alert-success">Password Updated</div>
              )}
            </div>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Benutzername eingeben"
                name="password1"
                value={newPassword}
                autoComplete="off"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="password2"
                placeholder="Benutzername eingeben"
                value={confirmNewPassword}
                autoComplete="off"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Button
                disabled={loading}
                className="col-md-12"
                onClick={updatePasswordHandler}
              >
                Update Password
              </Button>
              <div className="text-right">
                <Link to="/login">Want to login?</Link>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
