import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/** CSS */
import styles from "./MyOrderButton.module.scss";

function MyOrderButton() {

  return (
    <>
      <Link
        to="/order"
        className={styles.myOrderButton + " ml-2"}
      >
        MY ORDERS
      </Link>
    </>
  );
}

export default MyOrderButton;
