import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "./userContext";
import DefaultLayout from '../layout/DefaultLayout'

function ProtectedRoute({ children, ...rest }) {
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && user.username) {
          return (
            <DefaultLayout>{children}</DefaultLayout>
          );
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
