import React, { useState } from "react";
import styles from "./Checkbox.module.scss";
import Col from "react-bootstrap/Col";
import { ReactComponent as Plus } from "../../assets/images/plus-light.svg";
import { ReactComponent as Minus } from "../../assets/images/minus-light.svg";

function Checkbox({
  filter,
  filters,
  parentIndex,
  showPropertyCounts,
  counts,
  clickHandler,
  resets,
}) {
  const [show, setShow] = useState(false);

  const expandHandler = () => {
    setShow(!show);
  };

  const selectHandler = (e, parentIndex, index) => {
    if (e.target.checked) {
      clickHandler({
        action: "add",
        index: index,
        parentIndex: parentIndex,
      });
    } else {
      clickHandler({
        action: "remove",
        index: index,
        parentIndex: parentIndex,
      });
    }
  };
  return (
    <Col md={12} className={"mt-2 border-bottom " + styles.filterHeight}>
      <div onClick={expandHandler} className="pointer">
        <span className={styles.filterHeading}>{filter.filterName}</span>
        <span className="float-right pointer">
          {show ? (
            <Minus className={styles.filterIcon} />
          ) : (
            <Plus className={styles.filterIcon} />
          )}
        </span>
      </div>
      <div className={["filterScroll", show ? "show" : "hide"].join(" ")}>
        {filter.values.map((filItem, index) => {
          return (
            <label className="inputCustomCheckbox form-check" key={index}>
              <input
                id={parentIndex + "_" + index}
                name={parentIndex}
                index={index}
                type="checkbox"
                onChange={(e) => selectHandler(e, parentIndex, index)}
                checked={filItem.selected}
              />
              <span
                className="selectedMark"
                index={index}
              ></span>
              <label
                htmlFor={parentIndex + "_" + index}
                className="form-check-label"
              >
                {filItem.name +
                  " " +
                  (showPropertyCounts
                    ? "(" + counts[filter.systemName][filItem.name] + ")"
                    : "")}
              </label>
            </label>
          );
        })}
      </div>
    </Col>
  );
}

export default Checkbox;
