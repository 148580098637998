import React, { Component, useEffect, useState } from "react";
import classnames from "classnames";

import styles from "./ExpandButton.module.scss";
import BaseButton from "./BaseButton";

class ExpandButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <a
          id={"media_" + this.props.item.id}
          href={void 0}
          title="Vorschau öffnen"
          onClick={(e) => {
            this.props.handler(e);
          }}
          className={classnames(this.props.styles[this.props.classId], styles.iconExpand)}
        ></a>
      </React.Fragment>
    );
  }
}

ExpandButton = BaseButton(ExpandButton);

export default ExpandButton;
