import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import InitialLayout from '../layout/Initial'

function PublicRoute({ children, ...rest }) {
  let substring = 'selection/share';
  return (
    <Route
      {...rest}
      render={(props) => {
        if(props.location.pathname.includes(substring)){
            return <>{children}</>;
        }
        else{
          return <InitialLayout>{children}</InitialLayout>;
        }
      }}
    />
  );
}

export default PublicRoute;
