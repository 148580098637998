import React from "react";

import styles from "./Loader.module.scss";
import LoaderImg from "../../assets/images/preloader.svg";
import Img from "react-bootstrap/Image";
import classnames from "classnames";

const Loader = (props) => {
  return (
    <>
      <div className={styles.backdrop}></div>
      <Img
        src={LoaderImg}
        width="60 "
        height="60"
        className={classnames(styles.centered)}
        alt="Loader"
      />
    </>
  );
};

export default Loader;
