import React, { useState, useMemo, useEffect, useRef } from "react";
import ErrorBoundry from "./components/common/ErrorBoundry";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";
import qs from "qs"; //Query string parser
// import { HashRouter as Router, Switch, Route } from "react-router-dom"; //production use??
import ProtectedRoute from "./util/ProtectedRoute";
import PublicRoute from "./util/PublicRoute";

/** Shared Contexts */
import AppContext from "./util/appContext";

/** Common CSS */
import "./assets/css/App.scss";

/** Components */
import Login from "./components/login/Login";
import Loader from "./components/common/Loader";
import MySelections from "./components/selection/MySelections";
import MyOrders from "./components/order/MyOrder";
import MyOrderView from "./components/order/MyOrderView";
import SelectionShareView from "./components/selection/SelectionShareView";
import ForgotPassword from "./components/login/ForgotPassword";
import ChangePassword from "./components/login/ChangePassword";
import Register from "./components/register/Register";
import Welcome from "./components/search/Welcome";
import RegisterAcknowledge from "./components/register/RegisterAcknowledge";

/** Lazy loading Components */
const Search = React.lazy(() => import("./components/search/Search"));

function App() {
  const location = useLocation();
  const activeCategory = useRef(0);
  const redirectLocation = useRef("/search");

  //Message Context
  const [message, setMessage] = useState({ title: "", message: "" });
  const messageMemo = useMemo(() => ({ message, setMessage }), [message, setMessage]);

  //User Context
  const userData = JSON.parse(localStorage.getItem("user")) || {};
  const [user, setUser] = useState(userData);
  const userMemo = useMemo(() => ({ user, setUser }), [user, setUser]);

  //Filter Context
  const [filter, setFilter] = useState([]);
  const filterMemo = useMemo(
    () => ({ filter, setFilter }),
    [filter, setFilter]
  );

  //Result Context
  const [result, setResult] = useState(null);
  const resultMemo = useMemo(
    () => ({ result, setResult }),
    [result, setResult]
  );
  
  //Retrive user settings from local storage during the initial startup
  useEffect(() => {
    let queryStringFilters = qs.parse(location.search);

    if (queryStringFilters && queryStringFilters.categoryId) {
      activeCategory.current = queryStringFilters.categoryId;
    }
    setFilter({ categoryId: activeCategory.current, sort: "id" });

    if (location && location.pathname && location.pathname !== "/") {
      redirectLocation.current = location.pathname + location.search;
    }
  }, [location]);

  //Set user settings to the local storage on change to user context
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  //Set default settings
  useEffect(() => {
    setUser({ ...user, ...{ gridMode: "MEDIUM", loading: false, resultLoading: false } });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //Set prevPathName to the local storage for my selection goBack
  if(window.location.pathname !=='/selection'){
    localStorage.setItem("prevPathName", JSON.stringify(window.location.pathname));
    localStorage.setItem("searchParam", JSON.stringify(window.location.search));
  }  
  
  return (
    <>
      <ErrorBoundry>
        <AppContext user={userMemo} filter={filterMemo} result={resultMemo} message={messageMemo}>
          <Router>
            <React.Suspense fallback={<Loader />}>
              <Switch>
                {/* Public Routes */}
                <PublicRoute exact path="/login">
                  <Login />
                </PublicRoute>
                <PublicRoute exact path="/register">
                  <Register />
                </PublicRoute>
                <PublicRoute exact path="/registrationComplete">
                  <RegisterAcknowledge />
                </PublicRoute>
                <PublicRoute exact path="/forgotPassword">
                  <ForgotPassword />
                </PublicRoute>
                <PublicRoute exact path="/forgotPassword/:key">
                  <ChangePassword />
                </PublicRoute>
                <PublicRoute path="/selection/share/:hash">
                  <SelectionShareView />
                </PublicRoute>
                {/* Protected Routes */}
                <ProtectedRoute exact path="/">
                  <Redirect to={redirectLocation.current} />
                </ProtectedRoute>
                <ProtectedRoute path="/welcome">
                  <Welcome />
                </ProtectedRoute>
                <ProtectedRoute path="/search">
                  <Search />
                </ProtectedRoute>
                <ProtectedRoute exact path="/selection">
                  <MySelections />
                </ProtectedRoute>
                <ProtectedRoute exact path="/order">
                  <MyOrders />
                </ProtectedRoute>
                <ProtectedRoute exact path="/order/:orderId/:orderDate">
                  <MyOrderView />
                </ProtectedRoute>
              </Switch>
            </React.Suspense>
          </Router>
        </AppContext>
        {user && user.loading ? <Loader /> : ""}
      </ErrorBoundry>
    </>
  );
}

export default App;
