import React, { useEffect, useState } from 'react';

function Background({ styles, background }) {

    if (background && background['mediaUrl']) {
        switch (background['mediaType']) {
            case 'video':
                return (
                    <video autoPlay={true} muted={true} loop={true} className={styles.backgroundVideo}>
                        <source src={background['mediaUrl']} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                );
            default:
                return (
                    <img src={background['mediaUrl']} alt="background" className={styles.backgroundImage} />
                );
        }
    } else {
        return null;
    }

}

export default Background
