import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useAPI } from "../../util/useAPI";
import { UserContext } from "../../util/userContext";

/** Bootstrap components */
import Button from "react-bootstrap/Button";


/** CSS */
import styles from "./RemoveAllSelection.module.scss";

function RemoveAllSelection() {
  const { user, setUser } = useContext(UserContext);
  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {
      method: "DELETE",
      url: "/selections",
    },
    { manual: true }
  );

  const remove = () => {
    setUser({ ...user, ...{ loading: true } });
    requestHandler({});
  };

  useEffect(() => {
    if (data && data.data) {
      setUser({
        ...user,
        ...{
          totalRecords: 0,
          loading: false,
          clearSelection: true,
          selectionCount: 0,
          selectionIds: [],
        },
      });
    }
  }, [data]);

  return (
    <>
      <Button
        id="removeAllSelection"
        href={void 0}
        className={styles.buttonStyle}
        onClick={() => {
          remove();
        }}>
          Empty Box
      </Button>
    </>
  );
}

export default RemoveAllSelection;
