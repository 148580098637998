import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

/** CSS */
import styles from "./DownloadAllButton.module.scss";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useAPI } from "../../util/useAPI";
import { UserContext } from "../../util/userContext";
import { useParams } from "react-router-dom";

function DownloadAllButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [templates, setTemplates] = useState(null);
  const [classifications, setClassifications] = useState({});
  const { user, setUser } = useContext(UserContext);
  const { hash } = useParams();
  let { orderId } = useParams();

  const [{ data, loading, error, response }, requestHandler] = useAPI(
    {
      method: "GET",
    },
    { manual: true }
  );

  const downloadAllButtonClickHandler = () => {
    setUser({ ...user, loading: true });
    let url = "";
    if(props.mode === "order"){
      url = "/orders/downloadOptions?orderId="+orderId;
    }
    else{
      url = "/selections/downloadOptions";
      if (hash) {
        url = "/selections/downloadOptions?hash=" + hash;
      }
    }
    
    requestHandler({
      url: url,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.target.submit();
    handleClose();
  };

  useEffect(() => {
    if (data) {
      setTemplates(data.data);
      setClassifications(data.classifications);
    }
  }, [data]);

  useEffect(() => {
    if (templates) {
      setUser({ ...user, loading: false });
      handleShow();
    }
  }, [templates]);

  return (
    <>
      <Button
        id="downloadAllButton"
        className={styles.buttonStyle}
        onClick={() => {
          downloadAllButtonClickHandler();
        }}
      >
        Download All
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form
          onSubmit={handleSubmit}
          method="post"
          target="_blank"
          action={props.mode === "order" ? process.env.REACT_APP_MEDIAPORTAL_API_URL + "/orders/archiveDownload?orderId="+orderId+"&fileName="+props.fileName : `${process.env.REACT_APP_MEDIAPORTAL_API_URL}/selections/archiveDownload?fileName=${props.fileName}${hash ? "&hash=" + hash : ""}`}
        >
          <Modal.Header closeButton>
            <Modal.Title id="share-link" className={styles.modalTitle}>
              DOWNLOAD ALL
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={styles.bodyText}>
              Make sure to choose image size for any selected images and video
              size for any selected videos.
            </p>
            <Row className={styles.bodyAlign}>
              {templates
                ? templates.map((item, index) => {
                    return (
                      <Col
                        className={classnames("col", "col-sm-12", "col-md-4")}
                        key={index}
                      >
                        <div className={styles.cattitle}>{item.title}</div>
                        <FormGroup>
                          {item.templates.map((template, index) => {
                            return (
                              <>
                              <label className="inputCustomCheckbox form-check">
                                  <input id={item.classification + "_" + index} name={item.classification + "[]"} 
                                  value={template.templateId ?? ""} type="checkbox" className={styles.check} 
                                  disabled={ !classifications[item.classification] } />
                                  <span className="selectedMark"></span>
                                  <label htmlFor={item.classification + "_" + index} className="form-check-label">{template.name}</label>
                                </label>
                              </>
                            );
                          })}
                        </FormGroup>
                      </Col>
                    );
                  })
                : ""}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="secondary">
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Download
            </Button>
            {hash ? <input type="hidden" value={hash} name="hash" /> : ""}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default DownloadAllButton;
